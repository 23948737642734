import { Directive, HostListener, Input, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNoSpaceMaxLength]'
})
export class NoSpaceMaxLengthDirective {
  @Input() appNoSpaceMaxLength!: number;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue = inputElement.value;

    // Remove spaces and limit the length
    inputValue = inputValue.replace(/\s+/g, '').slice(0, this.appNoSpaceMaxLength);

    this.renderer.setProperty(inputElement, 'value', inputValue);
  }
}
