<div class="sidebar-wrapper" [ngClass]="{active: !shouldShow, inactive: shouldShow,isSuperAdmin : (user.chUserDTO.userType === 'SUPER_ADMIN') || (user.chUserDTO.userType == 'INCHARGE_ADMIN' && (router.url.split('/').includes('admin-management') || router.url.split('/').includes('settings'))) }" >
    <div class="main-sidebar">
        <!-- <div class="main-sidebar-header">
            <a href="javascript:void(0)" [inlineSVG]="'assets/images/Apperone_Pro.svg'" class="sidebar-logo"></a>
            <div class="sidebar-profile">
                <figure>
                    <img [src]="adminDetail.imageUrl" alt="profile-image" (error)="imgErrorHandler($event)">
                </figure>
                <div class="profile-info">
                    <h4>{{ adminDetail.firstName+" "+adminDetail.lastName }}</h4>
                    <a [href]="'mailto:'+adminDetail.email" class="btn-mailto"> {{ adminDetail.email }} </a>
                    <a href="javascript:void(0)" (click)="openFromProfile(account)" class="btn-view-profile">view
                        profile</a>
                </div>
            </div>
        </div> -->
        <div class="main-side-bar-menu">
            <ng-scrollbar>

                <div *ngIf='!superAdmin' class="sidebar-wrap">
                    <div href="javascript:void(0)" class="sidebar-heading  add-heading">
                        <span>

                          <a [routerLink]="['/trips']" [class.active]="router.url==='/trips'">
                            <i [inlineSVG]="'assets/images/icon-trips.svg'">
                            </i><span>Trips</span>
                          </a>
                        </span>
                    </div>
                    <!-- <ul class="sidebar-nav"> -->
                        <!-- <li>
                            <a [class.active]="router.url==='/trips'" (click)="changeSidebartwo('manage')"
                                [routerLink]="['/trips']">Manage</a>
                        </li>
                        <li>
                            <a [class.active]="router.url==='/trips/archive'" (click)="changeSidebartwo('archive')"
                                [routerLink]="['/trips/archive']">Archive</a>
                        </li>
                        <li>
                            <a [class.active]="router.url==='/trips/add-new'" (click)="changeSidebartwo('addnew')"
                                [routerLink]="['/trips/add-new']">Add New</a>
                        </li> -->
                        <!-- <li *ngIf="subAdmin">
                            <a [class.active]="router.url==='/trips/add-trip'" (click)="changeSidebartwo('addtrip')" [routerLink]="['/trips/add-trip']">Add Trip</a>
                        </li> -->
                    <!-- </ul> -->
                </div>

                <div  *ngIf="adminDetail.userType == 'ADMIN'"class="sidebar-wrap">
                    <div href="javascript:void(0)" class="sidebar-heading">
                      <a [routerLink]="['/trips/add-trip']" [class.active]="router.url==='/trips/add-trip'">
                        <i [inlineSVG]="'assets/images/plus-icon-fill.svg'" class="trip-sidebar-icon">
                        </i><span>Add Trip</span>
                      </a>
                    </div>
                    <!-- <ul class="sidebar-nav">
                        <li>
                            <a [class.active]="router.url==='/database'" (click)="changeSidebartwo('database')"
                                [routerLink]="['/database']">Manage</a>
                        </li>
                    </ul> -->
                </div>

                <div *ngIf='!superAdmin' class="sidebar-wrap">
                  <div href="javascript:void(0)" class="sidebar-heading">
                    <a [routerLink]="['/database']" [class.active]="router.url.split('/').includes('database')">
                      <i [inlineSVG]="'assets/images/icon-users.svg'">
                      </i><span>Groups</span>
                    </a>
                  </div>
                    <!-- <ul class="sidebar-nav">
                        <li>
                            <a [class.active]="router.url==='/database'" (click)="changeSidebartwo('database')"
                                [routerLink]="['/database']">Manage</a>
                        </li>
                    </ul> -->
                </div>

                <div *ngIf='!superAdmin && false' class="sidebar-wrap">
                  <div href="javascript:void(0)" class="sidebar-heading">
                      <a [routerLink]="['/trips']" [class.active]="router.url.split('/').includes('logs')">
                        <i [inlineSVG]="'assets/images/icon-logs.svg'"></i><span>Logs</span></a>
                  </div>
                </div>
                <!-- <div *ngIf='!superAdmin' class="sidebar-wrap">
                    <div href="javascript:void(0)" class="sidebar-heading">
                        <a href="javascript:void(0)" (click)="notificationsModal(notifications)"><i
                                [inlineSVG]="'assets/images/icon-notifications.svg'"></i><span>Notifications</span></a>
                    </div>
                </div> -->

                <div [ngClass]="{active: !shouldShow, inactive: shouldShow}" *ngIf='inchargeAdmin' class="sidebar-wrap">
                  <div href="javascript:void(0)" class="sidebar-heading">
                      <a [routerLink]="['/admin-management/list-administrator']"
                      [class.active]="router.url.split('/').includes('admin-management')"
                          (click)="changeSidebartwo('subAdminListing')">
                          <i [inlineSVG]="'assets/images/icon-sub-admin.svg'"></i>
                          <span>Sub Admin</span>
                      </a>
                  </div>
                </div>
                <!-- sprint 7 -->
                <!-- <div class="sidebar-wrap">
                    <div href="javascript:void(0)" class="sidebar-heading">
                        <a href="javascript:void(0)" (click)="goToHelpLink('https://goapperone.com/support')"><i
                                [inlineSVG]="'assets/images/icon-notifications.svg'"></i><span>Help</span></a>
                    </div>
                </div> -->
                <!-- sprint 7 -->

                <div *ngIf='superAdmin' class="sidebar-wrap">
                    <div href="javascript:void(0)" class="sidebar-heading">
                        <a [routerLink]="['/admin-management/list-administrator']" [class.active]="router.url.split('/').includes('admin-management') && !router.url.split('/').includes('billing-tab-list')"><i
                                [inlineSVG]="'assets/images/icon-admin.svg'"></i><span>Admin</span></a>
                    </div>
                </div>
                <div *ngIf='subAdminAsInchargeAdmin' class="sidebar-wrap">
                    <div href="javascript:void(0)" class="sidebar-heading">
                        <a [routerLink]="['/incharge-admin-management/list-administrator']" [class.active]="router.url.split('/').includes('incharge-admin-management')">
                            <i [inlineSVG]="'assets/images/icon-sub-admin.svg'"></i>
                            <span>Sub Admin</span>
                        </a>
                    </div>
                </div>
                <div *ngIf='superAdmin' class="sidebar-wrap">
                    <div href="javascript:void(0)" class="sidebar-heading">
                        <a [routerLink]="['/all-user-management/all-user-listing']" [class.active]="router.url.split('/').includes('all-user-management')">
                          <i [inlineSVG]="'assets/images/icon-users.svg'"></i><span>Users</span></a>
                    </div>
                </div>
                <div *ngIf='superAdmin && false' class="sidebar-wrap">
                    <div href="javascript:void(0)" class="sidebar-heading">
                        <a [routerLink]="['/admin-management/list-administrator']" [class.active]="router.url.split('/').includes('logs')">
                          <i [inlineSVG]="'assets/images/icon-logs.svg'"></i><span>Logs</span></a>
                    </div>
                </div>
                <div *ngIf='superAdmin' class="sidebar-wrap">
                  <div href="javascript:void(0)" class="sidebar-heading">
                      <a [routerLink]="['/app-information/list-app-information']" [class.active]="router.url.split('/').includes('app-information')">
                        <i [inlineSVG]="'assets/images/icon-info.svg'"></i><span>Info</span></a>
                  </div>
                </div>
                <div *ngIf='superAdmin && false' class="sidebar-wrap">
                  <div href="javascript:void(0)" class="sidebar-heading">
                      <a [routerLink]="['/admin-management/list-administrator']" [class.active]="router.url.split('/').includes('billing')">
                        <i [inlineSVG]="'assets/images/icon-billing.svg'"></i><span>Billing</span></a>
                  </div>
                </div>
                <div *ngIf='superAdmin' class="sidebar-wrap">
                  <div href="javascript:void(0)" class="sidebar-heading">
                      <a [routerLink]="['/admin-management/billing-tab-list']" (click)="billingData()" [class.active]="router.url.split('/').includes('billing-tab-list')">
                        <i [inlineSVG]="'assets/images/icon-billing.svg'"></i><span>Billing</span></a>
                  </div>
                </div>
                <div *ngIf='superAdmin' class="sidebar-wrap">
                  <div href="javascript:void(0)" class="sidebar-heading">
                      <a [routerLink]="['/promoCode']" [class.active]="router.url.split('/').includes('promoCode')">
                        <i [inlineSVG]="'assets/images/icon-promo-codes.svg'"></i><span>Promo Codes</span></a>
                  </div>
                </div>


                <!-- <div *ngIf='inchargeAdmin' class="sidebar-wrap">
                  <div href="javascript:void(0)" class="sidebar-heading">
                      <a [routerLink]="['/admin-management/list-administrator']">

                        <i
                              [inlineSVG]="'assets/images/icon-database.svg'"></i><span>Admin Management</span>
                            </a>
                  </div>
                </div> -->



            </ng-scrollbar>
        </div>
        <div class="main-sidebar-footer">
            <div class="sidebar-wrap">
                <div class="sidebar-heading">
                    <a [routerLink]="['/settings']" [class.active]="router.url.split('/').includes('settings')">
                      <i [inlineSVG]="'assets/images/icon-settings.svg'"></i><span>Settings</span>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="sub-sidebar" *ngIf="adminDetail.userType != 'SUPER_ADMIN' && !router.url.split('/').includes('admin-management') && !router.url.split('/').includes('settings')">

        <!-- trip name -->

        <div class="manage-trips" *ngIf="manageActive">
            <div *ngIf="!(queryParams.trip && !tripListing.length)" class="sub-sidebar-heading title-h3">
                <h3>Manage your trips</h3>
            </div>
            <div class="sub-sidebar-heading add-h3" *ngIf="adminDetail.userType !== 'ADMIN'">
                <a href="javascript:void(0)" class="add-trip-svg" [inlineSVG]="'assets/images/icon-datatable-add.svg'"
                [routerLink]="['/trips/add-new']">  </a>
                <h3 style="margin-left: 7px;cursor: pointer;"[routerLink]="['/trips/add-new']">Add a Trip</h3>
            </div>
            <!-- <div class="sub-sidebar-heading add-h3" style="justify-content: space-between;">
              <div style="display: flex; align-items: center;">
                <a href="javascript:void(0)" [inlineSVG]="'assets/images/icon-datatable-archive.svg'"
                [routerLink]="['/trips/archive']"></a>
                <h3 style="margin-left: 7px;margin-bottom: 3px; cursor: pointer;" [routerLink]="['/trips/archive']">Archived</h3>
              </div>
              <span *ngIf="archieveShow" style="color: #C3C7CC; padding-right: 28px;">{{ archieveTripCount }}</span>
            </div> -->
            <div *ngIf="queryParams.trip && !tripListing.length" class="sub-sidebar-heading">
                <a href="javascript:void(0)" [routerLink]="['/trips']" [inlineSVG]="'assets/images/icon-back-heading.svg'"
                    class="add-btn" style="margin: 20px;"></a>
                <h3>
                    {{ currentTrip.tripName }}
                </h3>
            </div>
            <ng-scrollbar>
                <div class="sub-sidebar-inner-menu-heading" style="display: none;">
                    <a href="javascript:void(0)" [inlineSVG]="'assets/images/icon-back-heading.svg'"
                        class="back-btn-heading"></a>
                    <h4>Washington D.C. Band Trip</h4>
                </div>
                <div *ngFor="let trip of tripListing;index as i" class="sub-menu-wrap inside-active "
                    [ngClass]="{'active':currentTripOptionDisplay === trip.chTrip?.id}" (click)="checkTarget($event)">
                    <h4>{{trip.chTrip?.tripName}} </h4>
                    <ul class="sub-menu-nav"  >
                        <li class="toggle" *ngFor="let tripTab of tripsidebarData">
                          <ng-container *ngIf="tripTab.condition(trip.chTrip.tripStatus)">
                            <a *ngIf="tripTab.name != 'Lists'" [routerLink]="tripTab.routerLink" [queryParams]="{ trip: trip.chTrip?.id, edit: 1 }">{{tripTab.name}}</a>
                            <!-- add onclick -->
                             <ng-container *ngIf="tripTab.name == 'Lists'">
                              <a class="addArrow" (click)="openMenu()" >{{tripTab.name}}</a>
                              <ul class="inner-menu-ul">
                                <ng-container *ngFor="let list of trip.chTrip?.lists; let index = index">
                                  <li class="ul-list-click">
                                    <div class="arrow-div-list" [ngClass]="{'open': listIndex == index }">
                                      <i svg-icon [inlineSVG]="'assets/images/down-arrow-blue.svg'" (click)="changeInnerMenu(index, $event)"></i>
                                      <a [routerLink]="['/trips/list']" class="li-list" [queryParams]="{ trip: trip.chTrip?.id, uuid: list.uuid }" [ngClass]="{'active': checkActiveList(list.uuid)}" (click)="changeInnerMenu(index, $event)">
                                        {{ list.type == 'TEAM' ? 'Teams' : list.alias }}
                                      </a>
                                      <!-- add onclick -->
                                     <ul class="inner-submenu-ul" >
                                         <li *ngFor="let subList of list.subAlias" cdkDropList (cdkDropListDropped)="attendeesDropped($event, subList.id, subList.name)"> <a [routerLink]="['/trips/list/attendees']" class="submenu-li" [ngClass]="{'active': checkActiveList(subList.id)}" [queryParams]="{ trip: trip.chTrip?.id, id: subList.id, uuid: list.uuid }"> {{subList.name}} </a> </li>
                                     </ul>
                                    </div>
                                  </li>
                                </ng-container>
                                <li>
                                  <a class="addPlus" (click)="createListModal(createList, trip.chTrip?.id)"> Create List </a>
                                </li>
                              </ul>
                            </ng-container>
                          </ng-container>
                        </li>
                    </ul>
                </div>

                <div class="sub-menu-wrap inside-active active" *ngIf="queryParams.trip && !tripListing.length">
                    <h4></h4>
                    <ul class="sub-menu-nav">
                      <li class="toggle" [ngClass]="{'active': tripTab.name == 'Lists' && showMenu}" *ngFor="let tripTab of tripsidebarData">
                        <ng-container *ngIf="tripTab.condition(currentTrip.tripStatus)">
                          <a *ngIf="tripTab.name != 'Lists'" [routerLink]="tripTab.routerLink" [queryParams]="{ trip: currentTrip.id, edit: 1 }">{{tripTab.name}}</a>
                          <ng-container *ngIf="tripTab.name == 'Lists'">
                            <a class="addArrow" (click)="openMenu()" >{{tripTab.name}}</a>
                            <!-- add onclick -->
                            <ul class="inner-menu-ul" *ngIf="tripTab.name == 'Lists'">
                              <ng-container *ngFor="let list of currentTrip?.lists; let i = index">
                                <li class="ul-list-click">
                                  <div class="arrow-div-list" [ngClass]="{ 'open': listIndex == i }">
                                    <i svg-icon [inlineSVG]="'assets/images/down-arrow-blue.svg'" (click)="changeInnerMenu(i, $event)" ></i>
                                    <a [routerLink]="['/trips/list']" class="li-list" [ngClass]="{'active': checkActiveList(list.uuid)}" [queryParams]="{ trip: currentTrip?.id, uuid: list.uuid }" (click)="changeInnerMenu(i, $event)">
                                      {{ list.type == 'TEAM' ? 'Teams' : list.alias }}
                                    </a>
                                   <!-- add onclick -->
                                    <ul class="inner-submenu-ul" >
                                        <li *ngFor="let subList of list.subAlias" cdkDropList (cdkDropListDropped)="attendeesDropped($event, subList.id, subList.name)"> <a [routerLink]="['/trips/list/attendees']" class="submenu-li" [ngClass]="{'active': checkActiveList(subList.id)}" [queryParams]="{ trip: currentTrip?.id, id: subList.id, uuid: list.uuid }"> {{subList.name}} </a> </li>
                                    </ul>
                                  </div>
                                </li >
                              </ng-container>
                              <li>
                                <a class="addPlus" (click)="createListModal(createList, currentTrip?.id)"> Create List </a>
                              </li>
                            </ul>
                          </ng-container>
                        </ng-container>
                      </li>
                  </ul>
                </div>
                <!-- Folder listing -->
                <!-- Please done remove this blank div from here -->
                <div class="sub-menu-wrap" (click)="checkTarget($event)"></div>
                <div class="sub-sidebar-heading add-h3">
                    <a href="javascript:void(0)" [inlineSVG]="'assets/images/icon-datatable-add.svg'"
                        (click)="folderlisting(folder)"></a>
                    <h3 style="margin-left: 7px;margin-bottom: 3px;">Add Folder</h3>
                </div>
                <div *ngFor="let folder of Folders" class="sub-menu-wrap custom-folder" (click)="checkTarget($event)">
                    <div class="folder-heading custom-folder-heading ">
                        <i [inlineSVG]="'assets/images/icon-folder-empty.svg'"></i>
                        <h4>{{folder.name}}</h4>
                        <div class="deletegroups">
                            <i style="cursor: pointer;" [inlineSVG]="'assets/images/icn-edit.svg'" (click)="editFolder(folder)"></i>
                            <i style="cursor: pointer;" [inlineSVG]="'assets/images/icon-more-delete.svg'" (click)="showTripAlert(folder.id, folder.name)"></i>
                        </div>
                    </div>
                    <ul class="sub-menu-nav folder-menu">
                        <li class="toggle " *ngFor="let trip of folder?.chTripDTO">
                            <a class="remove-arrow" href="javascript:void(0)">{{trip.tripName}}</a>
                            <ul class="sub-menu-nav"  >
                              <li class="toggle" *ngFor="let tripTab of tripsidebarData">
                                <ng-container *ngIf="tripTab.condition(trip.tripStatus)">
                                  <a *ngIf="tripTab.name != 'Lists'" [routerLink]="tripTab.routerLink" [queryParams]="{ trip: trip.id, edit: 1 }">{{tripTab.name}}</a>
                                  <ng-container *ngIf="tripTab.name == 'Lists'">
                                    <a class="addArrow" (click)="openMenu()" >{{tripTab.name}}</a>
                                    <ul class="inner-menu-ul" >
                                      <ng-container *ngFor="let list of trip?.lists; let index = index">
                                        <li class="ul-list-click">
                                          <div class="arrow-div-list" [ngClass]="{'open': listIndex == index }">
                                            <i svg-icon [inlineSVG]="'assets/images/down-arrow-blue.svg'" (click)="changeInnerMenu(index, $event)"></i>
                                            <a [routerLink]="['/trips/list']" class="li-list" [queryParams]="{ trip: trip.id, uuid: list.uuid }" [ngClass]="{'active': checkActiveList(list.uuid)}" (click)="changeInnerMenu(index, $event)">
                                              {{ list.type == 'TEAM' ? 'Teams' : list.alias }}
                                            </a>
                                            <!-- add onclick -->
                                            <ul class="inner-submenu-ul" >
                                                <li *ngFor="let subList of list.subAlias" cdkDropList (cdkDropListDropped)="attendeesDropped($event, subList.id, subList.name)"> <a [routerLink]="['/trips/list/attendees']" class="submenu-li" [ngClass]="{'active': checkActiveList(subList.id)}" [queryParams]="{ trip: trip.id, id: subList.id, uuid: list.uuid }"> {{subList.name}} </a> </li>
                                            </ul>
                                          </div>
                                        </li>
                                      </ng-container>
                                    </ul>
                                  </ng-container>
                                </ng-container>
                              </li>
                                <!-- <li class="toggle remove-arrow">
                                    <a [routerLink]="['/trips/info']" [queryParams]="{ trip: trip.id, edit: 1 }">Info</a>
                                </li>
                                <li class="toggle">
                                    <a class="remove-arrow" [routerLink]="['/trips/attendees']"
                                        [queryParams]="{trip:  trip.id}">Attendees</a>
                                    <ul class="inner-menu">
                                        <li class="remove-arrow">
                                            <a [routerLink]="['/trips/attendees/teams']"
                                                [queryParams]="{trip:  trip.id}">Teams</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toggle remove-arrow">
                                    <a  [routerLink]="['/trips/travel']" [queryParams]="{ trip: trip.id, edit: 1 }">Travel</a>
                                </li>
                                <li class="toggle remove-arrow">
                                    <a [routerLink]="['/trips/itinerary']"
                                        [queryParams]="{ trip: trip.id, edit:1 }">Itinerary</a>
                                </li>
                                <li class="toggle remove-arrow">
                                    <a [routerLink]="['/trips/chats']" [queryParams]="{trip:  trip.id}">Chat</a>
                                </li>
                                <li class="toggle remove-arrow">
                                    <a [routerLink]="['/trips/photos']" [queryParams]="{trip:  trip.id}">Photos</a>
                                </li>
                                <li class="toggle remove-arrow">
                                    <a [routerLink]="['/trips/notification']"
                                        [queryParams]="{trip:  trip.id}">Notifications</a>
                                </li> -->
                            </ul>
                        </li>
                    </ul>
                </div>
            </ng-scrollbar>
            <div class="sub-sidebar-heading add-h3" style="justify-content: space-between;">
              <div style="display: flex; align-items: center;">
                <a href="javascript:void(0)" [inlineSVG]="'assets/images/icon-datatable-archive.svg'"
                [routerLink]="['/trips/archive']"></a>
                <h3 style="margin-left: 7px;margin-bottom: 3px; cursor: pointer;" [routerLink]="['/trips/archive']">Archived</h3>
              </div>
              <span *ngIf="archieveShow" style="color: #C3C7CC; padding-right: 28px;">{{ archieveTripCount }}</span>
            </div>
        </div>

        <div class="archive-trips toggle" *ngIf="archiveActive">
            <div class="sub-sidebar-heading">
                <h3>Archive</h3>
            </div>
            <ng-scrollbar class="sidebar-scrollbar-archive" >
                <!-- <div class="sub-menu-wrap" (click)="checkTarget($event)"> -->
                <div *ngFor="let trip of tripListing" class="sub-menu-wrap inside-active"
                    [ngClass]="{'active':currentTripOptionDisplay === trip.chTrip?.tripName}" (click)="checkTarget($event)">
                    <h4>{{trip.chTrip?.tripName}} </h4>
                    <ul class="sub-menu-nav"  >
                        <li class="toggle" *ngFor="let tripTab of tripsidebarData">
                          <ng-container *ngIf="tripTab.condition(trip.chTrip.tripStatus)">
                            <a *ngIf="tripTab.name != 'Lists'" [routerLink]="tripTab.routerLink" [queryParams]="{ trip: trip.chTrip?.id, archive: 1 }">{{tripTab.name}}</a>
                            <!-- add onclick -->
                             <ng-container *ngIf="tripTab.name == 'Lists'">
                              <a class="addArrow" (click)="openMenu()" >{{tripTab.name}}</a>
                              <ul class="inner-menu-ul">
                                <ng-container *ngFor="let list of trip.chTrip?.lists; let index = index">
                                  <li class="ul-list-click">
                                    <div class="arrow-div-list" [ngClass]="{'open': listIndex == index }">
                                      <i svg-icon [inlineSVG]="'assets/images/down-arrow-blue.svg'" (click)="changeInnerMenu(index, $event)"></i>
                                      <a [routerLink]="['/trips/list']" class="li-list" [queryParams]="{ trip: trip.chTrip?.id, uuid: list.uuid, archive: 1 }" [ngClass]="{'active': checkActiveList(list.uuid)}" (click)="changeInnerMenu(index, $event)">
                                        {{ list.type == 'TEAM' ? 'Teams' : list.alias }}
                                      </a>
                                     <!-- add onclick -->
                                      <ul class="inner-submenu-ul" >
                                        <li *ngFor="let subList of list.subAlias" cdkDropList (cdkDropListDropped)="attendeesDropped($event, subList.id, subList.name)"> <a [routerLink]="['/trips/list/attendees']" class="submenu-li" [ngClass]="{'active': checkActiveList(subList.id)}" [queryParams]="{ trip: trip.chTrip?.id, id: subList.id, uuid: list.uuid, archive: 1 }"> {{subList.name}} </a> </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ng-container>
                                <!-- <li>
                                  <a class="addPlus" (click)="createListModal(createList, trip.chTrip?.id)"> Create List </a>
                                </li> -->
                              </ul>
                            </ng-container>
                          </ng-container>
                        </li>
                    </ul>
                </div>
                <!-- <div *ngFor="let folder of Folders" class="sub-menu-wrap"  (click)="checkTarget($event)"  >
                        <div class="folder-heading">
                            <i [inlineSVG]="'assets/images/icon-folder-empty.svg'"></i>
                            <h4>{{folder.name}}</h4>
                        </div>
                        <ul class="sub-menu-nav folder-menu">
                            <li class="toggle " *ngFor="let trip of folder.chTripDTO">
                                <a href="javascript:void(0)">{{trip.tripName}}</a>
                            </li>

                        </ul>
                    </div> -->

            </ng-scrollbar>
        </div>
        <div class="add-new-trips" *ngIf="addNewActive">
            <div class="sub-sidebar-heading">
                <h3>Add New</h3>
            </div>
            <ng-scrollbar>
                <div class="sub-menu-flow " [ngClass]="{'filled': newTripStatus >= 1, 'current': newTripStatus == 0}">
                    <h5>Basic Information</h5>
                    <span></span>
                    <span></span>
                </div>
                <div class="sub-menu-flow current"
                    [ngClass]="{'filled': newTripStatus >= 2, 'current': newTripStatus == 1}">
                    <h5>Invites</h5>
                    <span></span>
                    <span></span>
                </div>
                <div class="sub-menu-flow" [ngClass]="{'filled': newTripStatus >= 4, 'current': newTripStatus == 3}">
                    <h5>Itinerary</h5>
                    <span></span>
                    <span></span>
                </div>
                <div class="sub-menu-flow" [ngClass]="{'filled': newTripStatus >= 5 ,'current': newTripStatus == 4}">
                    <h5>Complete</h5>
                    <span></span>
                    <span></span>
                </div>
            </ng-scrollbar>
        </div>
        <div class="manage-database" *ngIf="databaseActive">
            <div class="sub-sidebar-heading">
                <h3>Manage</h3>
            </div>
            <ng-scrollbar>

                <ul class="database-nav custom-separtion">
                    <li *ngFor="let group of Grouplisting" class="sub-menu-wrap" [class.active] = "paramGroupId == group.id"
                    [id]="'group-section'+group.id" (click)="checkTarget($event);getGroupUsers(group.id,$event)">
                        <div class="folder-heading custom-folder-heading">
                            <h4>{{group.groupName}}</h4>
                            <div class="deletegroups">
                                <i style="cursor: pointer;" [inlineSVG]="'assets/images/icn-edit.svg'"
                                    (click)="editGroupName(group);;$event.stopPropagation()"></i>
                                <i style="cursor: pointer;" [inlineSVG]="'assets/images/icon-more-delete.svg'"
                                    (click)="deleteGroup(group.id);$event.stopPropagation()"></i>
                            </div>
                        </div>
                        <ul class="sub-menu-nav folder-menu" *ngIf="GroupUsers.length > 0">
                            <li class="toggle remove-arrow active">
                                <ul class="sub-menu-nav ">
                                    <li class="toggle remove-arrow" *ngFor="let user of GroupUsers">
                                        {{user.firstName +" "+user.lastName}}
                                        <span style="display: inline-block; vertical-align: middle;margin-left: 5px;">
                                            <i style="cursor: pointer;" [inlineSVG]="'assets/images/icon-more-delete.svg'"
                                                (click)="removeUserFromGroup(user);$event.stopPropagation()"></i>
                                        </span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <!-- <li *ngFor="let group of Grouplisting">
                        <a href="javascript:void(0)" (click)="getGroupUsers(group.id)">{{group.groupName}}
                            <div class="deletegroups">
                                <i [inlineSVG]="'assets/images/icn-edit.svg'" (click)="editGroup(group)"></i>
                                <i [inlineSVG]="'assets/images/icon-more-delete.svg'" (click)="deleteGroup(group.id)"></i>
                            </div>
                        </a>

                    </li> -->
                    <li>
                        <form class="add-grp-name-form">
                            <input type="text" #addgroupname (input)="groupname = $event.target.value;"
                                class="add-grp-name-filed" placeholder="Group Name">
                            <div *ngIf="groupsubmitted && groupname.trim() == ''" style="color: red;">
                                <div>Please provide group name.</div>
                            </div>
                        </form>
                    </li>
                    <li (click)="addnewGroup('add')">
                        <div class="add-grp-btn"><i [inlineSVG]="'assets/images/icon-manage-add.svg'"></i><strong>Add
                                New</strong></div>
                    </li>
                </ul>
            </ng-scrollbar>
        </div>
        <a href="javascript:void(0)" class="sidebar-arrow" (click)="sidebarToggle()"
            [ngClass]="{active: !shouldShow, inactive: shouldShow}"><i
                [inlineSVG]="'assets/images/icon-sidebar-arrow.svg'"></i></a>
    </div>
</div>

<ng-template #createList>
  <div class="settings-main create-popup">
    <form [formGroup]="createListForm" (ngSubmit)="onListSubmit()">
      <button type="button" class="close" aria-label="Close" (click)="closeModel()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-header">
        <h4>Create List</h4>
      </div>
      <ng-scrollbar>
        <div class="modal-body">
          <div class="two-col-form-main">
            <ul class="form-fields-listing">
              <li>
                <div class="form-grp">
                  <label>List Type</label>
                  <ng-multiselect-dropdown name="city" [data]="listType"
                    [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" placeholder="Select a List Type"
                    class="dropdown-lists">
                  </ng-multiselect-dropdown>
                  <div class="invalid-feedback" *ngIf="isListSubmitted && createListForm.controls.listType.errors">
                    <div *ngIf="createListForm.hasError('required','listType')">
                      Please select list type.
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="form-grp">
                  <label>{{ listName }}</label>
                  <input type="text" formControlName="listName" [placeholder]="listNamePlaceholder"
                    class="input-grp"
                    [ngClass]="{ 'is-invalid': isListSubmitted && createListForm.controls.listName.errors }">
                  <div class="invalid-feedback" *ngIf="isListSubmitted && createListForm.controls.listName.errors">
                    <div *ngIf="createListForm.hasError('required','listName')">
                      Please provide {{ listName }}.
                    </div>
                    <div *ngIf="createListForm.hasError('pattern','listName')">
                      Please provide valid {{ listName }}.
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="form-grp">
                  <label>Quantity</label>
                  <input type="text" formControlName="quantity" numbersOnly placeholder="Enter quantity" class="input-grp"
                    [ngClass]="{ 'is-invalid': isListSubmitted && createListForm.controls.quantity.errors }" min="0">
                  <div class="invalid-feedback" *ngIf="createListForm.controls.quantity.value <= 0 && createListForm.controls.quantity.touched && !createListForm.hasError('required','quantity') && !createListForm.hasError('pattern','quantity')">
                    Please provide quantity more than 0.
                  </div>
                  <div class="invalid-feedback" *ngIf="isListSubmitted && createListForm.controls.quantity.errors">
                    <div *ngIf="createListForm.hasError('required','quantity')">
                      Please provide quantity.
                    </div>
                    <div *ngIf="!createListForm.hasError('required','quantity') && createListForm.hasError('pattern','quantity')">
                      Please provide numerical value of quantity.
                    </div>
                  </div>
                </div>
              </li>
              <li *ngIf="false">
                <div class="check-grp new-checkbox" style="width: 454px !important;">
                  <input type="checkbox" name="check-examle" (click)="markAdminTeamMember($event)"
                    id="make-admin-as-leader">
                  <label style="margin-left: 26px;" for="make-admin-as-leader">Click this box to assign yourself as a trip
                    leader</label>
                </div>
              </li>
              <li *ngIf="false">
                <div class="add-new-leader">
                  <a href="javascript:void(0)" class="btn-add-new" (click)="attendeesModal(attendees)">Assign
                    Team Leader <i [inlineSVG]="'assets/images/icon-create-folder.svg'"></i></a>
                </div>
              </li>
            </ul>
            <div class="custom-data-table create-team-table" *ngIf="false">
              <table datatable class="row-border hover" style="border: none;">
                <thead style="display: none;">
                  <tr>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="selectedTripOfficial.length == 0">
                    <!-- colspan="4"-->
                    <td><strong class="empty-screen-msg">No Team Leader Added</strong></td>
                  </tr>
                  <tr *ngFor="let team of selectedTripOfficial">
                    <td>
                      <div class="d-flex align-items-center"><span class="dt-profile-img"><img src="{{team.image}}"
                            onError="this.src='assets/images/profile-placeholder-image.svg'"
                            alt="profile-image"></span><b class="dt-profile-name">{{team.firstName+" "+
                          team.lastName}}</b></div>
                    </td>
                    <td><a href="tel:{{team.phone}}" class="contact-to">{{team.phone}}</a></td>
                    <td>{{team.type}}</td>
                    <td><a href="javascript:void(0)" [inlineSVG]="'assets/images/icon-close-red.svg'"
                        (click)="selectOfficial(team,selectedTripOfficial)"></a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-scrollbar>
      <div class="modal-footer">
        <a href="javascript:void(0)" class="btn-grey min-btn mr-btn" (click)="closeModel()">Cancel</a>
        <input type="submit" value="{{ submitButton }}" class="btn-blue min-btn" [disabled]="listLoader">
      </div>
    </form>
  </div>
</ng-template>

<!-- Attendees Popup -->
<ng-template #attendees>
  <div class="settings-main">
    <button type="button" class="close" aria-label="Close" (click)="closeAttendees()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-header">
      <h4>Attendees</h4>
      <div class="modal-invites-header-wrap">
        <form>
          <ul class="modal-invites-listing">
            <li>
              <input type="text" class="input-grp" name="AttendeesSearch" (input)="searchChanges($event)"
                placeholder="Search">
              <div *ngIf="textInvitesSearchLoading" class="search-loader"></div>
            </li>
          </ul>
        </form>
      </div>
    </div>
    <ng-scrollbar>
      <div class="modal-body">
        <div class="custom-data-table modal-invite-table">
          <!-- [dtTrigger]="dtTrigger1" -->
          <table *ngIf="reloadAttendeesTable" datatable [dtOptions]="dtOptions" id="attendeesTable"
            class="row-border hover">
            <thead>
              <tr>
                <th style="background-image: none;">
                  <div class="check-grp">
                    <input type="checkbox" [(ngModel)]="isAllTeamOfficialSelected"
                      (click)="selectAllTeamOfficial(teamAttendeesUsers)" name="check-examle" id="official-selected">
                    <label for="official-selected"></label>
                  </div>
                </th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>TYPE</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of teamAttendeesUsers ;index as i">
                <ng-container *ngIf="user.type !== 'Incharge Admin'">
                  <td>
                    <div class="check-grp">
                      <input type="checkbox" name="check-examle" [checked]="isTeamOfficalAlreadySelected(user)"
                        (click)="selectOfficial(user,teamAttendeesUsers)" [id]="'teamOption-'+i">
                      <label [for]="'teamOption-'+i"></label>
                    </div>
                  </td>
                  <td><b class="dt-profile-name">{{ user.firstName ? user.firstName : '' }} {{ user.lastName ?
                      user.lastName : '' }}</b></td>
                  <td><a [href]="'mailto:'+user.email" class="contact-to">{{ user.email ? user.email : '' }}</a></td>
                  <td><span class="attendees-name-label">{{ user.type ? user.type.split('_').join(' ') : ''}}</span>
                  </td>
                </ng-container>
              </tr>

            </tbody>
            <tbody *ngIf="teamAttendeesUsers?.length == 0">
              <tr>
                <td class="no-data-available" style="text-align: center;">No attendees found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-scrollbar>
    <div class="modal-footer">
      <a href="javascript:void(0)" class="btn-grey min-btn mr-btn" (click)="closeAttendees()">Cancel</a>
      <a href="javascript:void(0)" class="btn-blue min-btn"
        (click)="modalAttendees.hide()">Add Team Leader</a>
    </div>
  </div>
</ng-template>

<!-- Terms of use Popup -->
<ng-template #groups>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalGroupname.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-header">
            <form>
                <ul class="form-fields-listing">
                    <li>
                        <div class="form-grp">
                            <label>Group Name</label>
                            <input type="text" [value]="groupnameadd" (input)="groupnameadd = $event.target.value;"
                                placeholder="Group Name" class="input-grp" style="width: 200% !important;">
                            <div class="invalid-feedback">
                                <div *ngIf="groupsubmittedupdate && groupnameadd == ''">
                                    Please provide group name.
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <input type="button" (click)="addnewGroup('update')" value="Update"
                                class="btn-blue min-btn">
                        </div>
                    </li>
                </ul>
            </form>
        </div>
    </div>
</ng-template>


<!-- Details Popup -->
<ng-template #folder>
    <div class="details-main">
        <button style="z-index: 9999999;" type="button" class="close" aria-label="Close"
            (click)="isCreateEmptyFolderSubmitted = false; modalFolder.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <!-- <a href="javascript:void(0)" class="modal-close-btn" (click)="addfolderSubmitted = false; isCreateEmptyFolderSubmitted = false; modalFolder.hide()"
        [inlineSVG]="'assets/images/icon-popup-close.svg'"></a> -->
        <div class="modal-body">
            <div class="tabs-details">
                <div class="modal-header" style="margin-top: 17px;"> </div>
                <div>
                    <form [formGroup]="createEmptyFolderForm" (ngSubmit)="createEmptyFolder()">
                        <ul class="form-fields-listing">
                            <li>
                                <div class="form-grp">
                                    <label>Folder Name</label>
                                    <input type="text" formControlName="folderName" placeholder="Folder Name"
                                        class="input-grp"
                                        [ngClass]="{ 'is-invalid': isCreateEmptyFolderSubmitted && createEmptyFolderForm.controls.folderName.errors }">
                                    <div class="invalid-feedback"
                                        *ngIf="isCreateEmptyFolderSubmitted && createEmptyFolderForm.controls.folderName.errors">
                                        <div *ngIf="createEmptyFolderForm.hasError('required','folderName')">
                                            Please provide folder name.
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <input type="submit" value="Save" class="btn-blue min-btn">
                                </div>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Settings Popup -->
<ng-template #settings>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalSettings.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-header">
            <h4>Settings</h4>
        </div>
        <ng-scrollbar>
            <div class="modal-body">
                <ul class="settings-listing">
                    <li>
                        <a href="javascript:void(0)" (click)="accountModal(account)">
                            <span>My Account</span><i [inlineSVG]="'assets/images/icon-settings-right-arrow.svg'"></i>
                        </a>
                    </li>
                    <li *ngIf="adminDetail.userType != 'SUPER_ADMIN'">
                        <a href="javascript:void(0)" (click)="licenseModal(license)">
                            <span>License</span><i [inlineSVG]="'assets/images/icon-settings-right-arrow.svg'"></i>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="changepasswordModal(changepassword)">
                            <span>Change Password</span><i
                                [inlineSVG]="'assets/images/icon-settings-right-arrow.svg'"></i>
                        </a>
                    </li>
                    <li *ngIf="adminDetail.userType == 'SUPER_ADMIN'">
                        <a href="javascript:void(0)" (click)="mobileVersionModel(mobileVersionEntry)">
                            <span>Mobile Version Update</span><i
                                [inlineSVG]="'assets/images/icon-settings-right-arrow.svg'"></i>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="aboutModal(about)">
                            <span>About</span><i [inlineSVG]="'assets/images/icon-settings-right-arrow.svg'"></i>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="privacyModal(privacy)">
                            <span>Privacy Policy</span><i
                                [inlineSVG]="'assets/images/icon-settings-right-arrow.svg'"></i>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="termsModal(terms)">
                            <span>Terms of Use</span><i [inlineSVG]="'assets/images/icon-settings-right-arrow.svg'"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </ng-scrollbar>
        <!-- <div class="modal-footer">
            <a [routerLink]="['/login']" class="btn-logout" (click)="logout()" *ngIf="checkSession()">
              <i [inlineSVG]="'assets/images/icon-logout.svg'"></i>
              Log out
            </a>
        </div> -->
    </div>
</ng-template>

<!-- My Account Popup -->
<ng-template #account>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalAccount.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <form [formGroup]="editAdminForm" (ngSubmit)="updateProfile()">
            <div class="modal-header">
                <div class="header-info">
                    <a href="javascript:void(0)" *ngIf="isHidden" (click)="modalAccount.hide()"><i
                            [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
                    <a href="javascript:void(0)" *ngIf="!isHidden" (click)="modalAccount.hide()"><i
                            [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Go Back</a>
                    <h3>My Account</h3>
                    <input *ngIf="isEditing" type="submit" value="Update" class="btn-edit-save">
                    <input *ngIf="!isEditing" type="button" (click)="edit()" value="Edit" class="btn-edit-save">
                    <!-- <a href="javascript:void(0)" class="btn-edit-save">EDIT</a> -->
                </div>
            </div>
            <ng-scrollbar>
                <div class="modal-body">
                    <div class="my-account-form">
                        <div class="auth-wrap">
                            <div class="auth-form-fields">
                                <div class="upload-profile">
                                    <figure>
                                        <img class="profile-placeholder-img" [src]="selectedImage"
                                            alt="profile-placeholder-img" (error)="imgErrorHandler($event)" />
                                    </figure>
                                    <!-- <input type="file" id="upload-profile-pic" (change)="onFileSelect($event)" #f_input >
                                    <label *ngIf="isEditing" (click)="f_input.click()"
                                        [inlineSVG]="'assets/images/icon-plus.svg'"></label> -->

                                    <input type="file" id="upload-profile-pic" (change)="onFileSelect($event)" accept="image/png, image/jpeg, image/jpg, image/gif">
                                    <label *ngIf="isEditing" for="upload-profile-pic" [inlineSVG]="'assets/images/icon-plus.svg'"></label>
                                </div>
                                <ul>
                                    <li>
                                        <div class="form-grp">
                                            <input type="text" class="input-grp" [readonly]="!isEditing"
                                                formControlName="firstName" placeholder="First Name"
                                                [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.firstName.errors }">
                                            <div class="invalid-feedback"
                                                *ngIf="profileSumitted && editAdminForm.controls.firstName.errors">
                                                <div *ngIf="editAdminForm.hasError('required','firstName')">
                                                    Please provide first name.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <input type="text" class="input-grp" [readonly]="!isEditing"
                                                formControlName="lastName" placeholder="Last Name"
                                                [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.lastName.errors }">
                                            <div class="invalid-feedback"
                                                *ngIf="profileSumitted && editAdminForm.controls.lastName.errors">
                                                <div *ngIf="editAdminForm.hasError('required','lastName')">
                                                    Please provide last name.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <input type="email" class="input-grp" formControlName="email"
                                                placeholder="Email" disabled
                                                [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.email.errors }">
                                            <div class="invalid-feedback"
                                                *ngIf="profileSumitted && editAdminForm.controls.email.errors">
                                                <div *ngIf="editAdminForm.hasError('required','email')">
                                                    Please provide email.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <input type="text" class="input-grp" formControlName="phone"
                                                placeholder="Phone Number" disabled
                                                [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.phone.errors }">
                                            <div class="invalid-feedback"
                                                *ngIf="profileSumitted && editAdminForm.controls.phone.errors">
                                                <div *ngIf="editAdminForm.hasError('required','phone')">
                                                    Please provide phone number.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <input type="text" class="input-grp" [readonly]="!isEditing"
                                                formControlName="position" placeholder="Position"
                                                [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.position.errors }">
                                            <div class="invalid-feedback"
                                                *ngIf="profileSumitted && editAdminForm.controls.position.errors">
                                                <div *ngIf="editAdminForm.hasError('required','position')">
                                                    Please provide position.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <input type="text" class="input-grp" [readonly]="!isEditing"
                                                formControlName="organization" placeholder="Organization"
                                                [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.organization.errors }">
                                            <div class="invalid-feedback"
                                                *ngIf="profileSumitted && editAdminForm.controls.organization.errors">
                                                <div *ngIf="editAdminForm.hasError('required','organization')">
                                                    Please provide organization.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-scrollbar>
            <div class="modal-footer">

            </div>
        </form>
    </div>
</ng-template>

<ng-template #mobileVersionEntry>
  <div class="settings-main">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeVersionUpdateModel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <form
      [formGroup]="updateMobileVersionForm"
      (ngSubmit)="updateMobileVersion()"
    >
      <div class="modal-header">
        <div class="header-info">
          <a
            href="javascript:void(0)"
            (click)="closeVersionUpdateModel()"
          >
            <i [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
          <h3>Mobile Version Update</h3>
          <input
            type="submit"
            value="SAVE"
            class="btn-edit-save"
          >
          <!-- <a href="javascript:void(0)" class="btn-edit-save">SAVE</a> -->
        </div>
      </div>
      <ng-scrollbar>
        <div class="modal-body">
          <div class="my-account-form Mobile-version-update">
            <div class="auth-wrap">
              <div class="auth-form-fields ">
                <ul>
                  <li>
                    <div class="form-grp">
                      <div>
                        Select application name
                      </div>
                      <div class="mobile-version-radio-grp">
                        <div class="radio-grp">
                          <input
                            type="radio"
                            (click)="setApplicationName('Apperone')"
                            name="app-name"
                            id="apperone"
                            autocomplete="false"
                          />
                          <label for="apperone">Apperone</label>
                        </div>
                        <div class="radio-grp">
                          <input
                            type="radio"
                            (click)="setApplicationName('Apperone Pro')"
                            name="app-name"
                            id="apperone-pro"
                            autocomplete="false"
                          />
                          <label for="apperone-pro">Apperone Pro</label>
                        </div>
                      </div>
                      <div
                        class="invalid-feedbackss"
                        *ngIf="updateMobileVersionSubmited && updateMobileVersionForm.controls.applicationName.errors"
                      >
                        <div *ngIf="updateMobileVersionForm.hasError('required','applicationName')">
                          Please select application name.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="form-grp">
                      <input
                        type="text"
                        formControlName="appVersion"
                        class="input-grp"
                        placeholder="Application version"
                      >
                      <div
                        class="invalid-feedbackss"
                        *ngIf="updateMobileVersionSubmited && updateMobileVersionForm.controls.appVersion.errors"
                      >
                        <div *ngIf="updateMobileVersionForm.hasError('required','appVersion')">
                          Please provide application version.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="form-grp">
                      <div>
                        Select platform
                      </div>
                      <div class="mobile-version-radio-grp">
                        <div class="radio-grp">
                          <input
                            type="radio"
                            (click)="selectPlatform('ANDROID')"
                            name="platform"
                            id="android"
                            autocomplete="false"
                          />
                          <label for="android">Android</label>
                        </div>
                        <div class="radio-grp">
                          <input
                            type="radio"
                            (click)="selectPlatform('IOS')"
                            name="platform"
                            id="ios"
                            autocomplete="false"
                          />
                          <label for="ios">IOS</label>
                        </div>
                      </div>
                      <div
                        class="invalid-feedbackss"
                        *ngIf="updateMobileVersionSubmited && updateMobileVersionForm.controls.platform.errors"
                      >
                        <div *ngIf="updateMobileVersionForm.hasError('required','platform')">
                          Please select platform.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="form-grp">
                      <div>
                        Would you like to force an update for the application?
                      </div>
                      <div class="mobile-version-radio-grp">
                        <div class="radio-grp">
                          <input
                            type="radio"
                            (click)="setForceUpdate(true)"
                            name="force-update"
                            id="yes"
                            autocomplete="false"
                          />
                          <label for="yes">Yes</label>
                        </div>
                        <div class="radio-grp">
                          <input
                            type="radio"
                            (click)="setForceUpdate(false)"
                            name="force-update"
                            id="no"
                            autocomplete="false"
                          />
                          <label for="no">No</label>
                        </div>
                      </div>
                      <div
                        class="invalid-feedbackss"
                        *ngIf="updateMobileVersionSubmited && updateMobileVersionForm.controls.forceUpdate.errors"
                      >
                        <div *ngIf="updateMobileVersionForm.hasError('required','forceUpdate')">
                          Please select yes or no.
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ng-scrollbar>
      <div class="modal-footer">

      </div>
    </form>
  </div>
</ng-template>

<!-- License Popup -->
<ng-template #license>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalLicense.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <form>
            <div class="modal-header">
                <div class="header-info">
                    <a href="javascript:void(0)" (click)="modalLicense.hide()"><i
                            [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
                    <h3>License</h3>
                </div>
            </div>
            <ng-scrollbar>
                <div class="modal-body">
                    <div class="my-account-form">
                        <div class="auth-wrap">
                            <div class="auth-form-fields">
                                <ul>
                                    <li>
                                        <div class="form-grp">
                                            <div class="license-wrap">
                                                <span>{{ adminDetail.adminLicenceKey }}</span>
                                                <strong>{{ adminDetail.activated ? "Active" : "Inactive"}}</strong>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-scrollbar>
            <div class="modal-footer">

            </div>
        </form>
    </div>
</ng-template>

<!-- Change Password Popup -->
<ng-template #changepassword>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalChangepassword.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
            <div class="modal-header">
                <div class="header-info">
                    <a href="javascript:void(0)" (click)="modalChangepassword.hide()"><i
                            [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
                    <h3>Change Password</h3>
                    <input type="submit" value="SAVE" class="btn-edit-save">
                    <!-- <a href="javascript:void(0)" class="btn-edit-save">SAVE</a> -->
                </div>
            </div>
            <ng-scrollbar>
                <div class="modal-body">
                    <div class="my-account-form">
                        <div class="auth-wrap">
                            <div class="auth-form-fields">
                                <ul>
                                    <li>
                                        <div class="form-grp">
                                            <div class="form-icon">
                                                <input [type]="showCurrentPassword ? 'text' : 'password'" formControlName="currentPassword" class="input-grp" placeholder="Current Password">
                                                <button type="button" (click)="currentPassword()">
                                                    <img [src]="showCurrentPassword ? '/assets/images/hide.svg' : '/assets/images/view.svg'" alt="{{showCurrentPassword ? 'Hide' : 'Show'}}">
                                                </button>
                                            </div>
                                            <div class="invalid-feedbackss"
                                                *ngIf="resetPasswordSubmited && changePasswordForm.controls.currentPassword.errors">
                                                <div *ngIf="changePasswordForm.hasError('required','currentPassword')">
                                                    Please provide current password.
                                                </div>
                                                <div
                                                    *ngIf="changePasswordForm.hasError('leadingTrailingSpaces', 'currentPassword')">
                                                    Passwords must not contain white spaces, Please enter valid password
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <div class="form-icon">
                                                <input [type]="showNewPassword ? 'text' : 'password'" formControlName="newPassword" class="input-grp" placeholder="New Password">
                                                <button type="button" (click)="newPassword()">
                                                    <img [src]="showNewPassword ? '/assets/images/hide.svg' : '/assets/images/view.svg'" alt="{{showNewPassword ? 'Hide' : 'Show'}}">
                                                </button>
                                            </div>
                                            <div class="invalid-feedbackss"
                                                *ngIf="resetPasswordSubmited && changePasswordForm.controls.newPassword.errors">
                                                <div *ngIf="changePasswordForm.hasError('required','newPassword')">
                                                    Please provide new password.
                                                </div>
                                                <div
                                                    *ngIf="changePasswordForm.hasError('leadingTrailingSpaces', 'newPassword')">
                                                    Passwords must not contain white spaces, Please enter valid password
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <div class="form-icon">
                                                <input [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword" class="input-grp"
                                                    placeholder="Confirm Password">
                                                <button type="button" (click)="confirmPassword()">
                                                    <img [src]="showConfirmPassword ? '/assets/images/hide.svg' : '/assets/images/view.svg'" alt="{{showConfirmPassword ? 'Hide' : 'Show'}}">
                                                </button>
                                            </div>

                                            <div class="invalid-feedbackss"
                                                *ngIf="resetPasswordSubmited && changePasswordForm.controls.confirmPassword.errors">
                                                <div *ngIf="changePasswordForm.hasError('required','confirmPassword')">
                                                    Please provide confirm password.
                                                </div>
                                                <div
                                                    *ngIf="changePasswordForm.hasError('leadingTrailingSpaces', 'confirmPassword')">
                                                    Passwords must not contain white spaces, Please enter valid password
                                                </div>
                                            </div>
                                            <div class="invalid-feedback"
                                                *ngIf="!changePasswordForm.hasError('required','confirmPassword') && (changePasswordForm.value.newPassword != changePasswordForm.value.confirmPassword) && resetPasswordSubmited">
                                                <div>
                                                    Password did not match.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-scrollbar>
            <div class="modal-footer">

            </div>
        </form>
    </div>
</ng-template>

<!-- About Popup -->
<ng-template #about>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalAbout.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-header">
            <div class="header-info">
                <a href="javascript:void(0)" (click)="modalAbout.hide()"><i
                        [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
                <h3>About</h3>
            </div>
        </div>
        <ng-scrollbar>
            <div class="modal-body">
                <div class="static-content" [innerHTML]="aboutDetail">
                </div>
            </div>
        </ng-scrollbar>
        <div class="modal-footer">

        </div>
    </div>
</ng-template>

<!-- Privacy Policy Popup -->
<ng-template #privacy>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalPrivacy.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-header">
            <div class="header-info">
                <a href="javascript:void(0)" (click)="modalPrivacy.hide()"><i
                        [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
                <h3>Privacy Policy</h3>
            </div>
        </div>
        <ng-scrollbar>
            <div class="modal-body">
                <div class="static-content" [innerHTML]="privacyDetail">
                </div>
            </div>
        </ng-scrollbar>
        <div class="modal-footer">

        </div>
    </div>
</ng-template>

<!-- Terms of use Popup -->
<ng-template #terms>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalTerms.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-header">
            <div class="header-info">
                <a href="javascript:void(0)" (click)="modalTerms.hide()"><i
                        [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
                <h3>Terms of Use</h3>
            </div>
        </div>
        <ng-scrollbar>
            <div class="modal-body">
                <div class="static-content" [innerHTML]="termsDetail">
                </div>
            </div>
        </ng-scrollbar>
        <div class="modal-footer">

        </div>
    </div>
</ng-template>


<!-- Notifications Popup -->
<ng-template #notifications>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalNotifications.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <form>
            <div class="modal-header">
                <h4>Notifications</h4>
                <!-- <a href="javascript:void(0)" class="btn-clear-all">Clear All</a> -->
            </div>
            <ng-scrollbar>
                <div class="modal-body">
                    <div class="notifications-main" *ngIf="AdminNotifications.length > 0">
                        <div *ngFor="let notification of AdminNotifications">
                            <div class="notifications-wrap">
                                <h4 *ngIf="notification[0].istoday">Today</h4>
                                <h4 *ngIf="notification[0].isyesterday">Yesterday</h4>
                                <h4 *ngIf="!notification[0].isyesterday && !notification[0].istoday">
                                    {{notification[0].createdDate | date }}</h4>
                                <ul class="notifications-listing">
                                    <li *ngFor="let not of notification">
                                        <div class="notifications-listing-wrap">
                                            <strong>{{not.notificationTitle}}</strong>
                                            <p>{{not.notificationDescription}}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- <div class="notifications-wrap">
                            <h4>Yesterday</h4>
                            <ul class="notifications-listing">
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="notifications-wrap">
                            <h4>23/04/2020</h4>
                            <ul class="notifications-listing">
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                            </ul> -->
                        <!-- </div> -->
                    </div>
                    <div class="notifications-main" *ngIf="AdminNotifications.length == 0">
                        No Notifications
                    </div>
                </div>
            </ng-scrollbar>
            <div class="modal-footer">
                <a href="javascript:void(0)" class="btn-blue min-btn" (click)="modalNotifications.hide()">Done</a>
            </div>
        </form>
    </div>
</ng-template>
