<ng-template #template>
    <div class="beneficiary-main">
        <h4>Select Beneficiary</h4>
        <p>Glide Golf tournaments allow you to select a charity that your purchase benefits..</p>
        <div class="select-beneficiary">
            <form action="">
                <div>
                    <select name="" id="">
                        <option value="">Choose Organization</option>
                        <option value="">Choose Organization</option>
                        <option value="">Choose Organization</option>
                        <option value="">Choose Organization</option>
                    </select>
                </div>
                <div>
                    <input type="submit" value="Continue to Cart">
                </div>
            </form>
        </div>
    </div>
  </ng-template>