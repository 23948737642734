import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PhoneNumberService {

    phoneNumber : any;
    
    constructor(
        public http: HttpClient,
        public router: Router,
    ) { }

    onPhoneChange(event:any) {
        // console.log('event: ', event);
        var cleaned = ('' + event.target.value).replace(/\D/g, '');
        // console.log('cleaned: ', cleaned);
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        // console.log('match: ', match);
        if (match) {
          let number = '(' + match[2] + ') ' + match[3] + '-' + match[4];
          // console.log('number: ', number);
    
          return number;
        }
      };

      
  handlePhoneNumberFormat = phoneNumber => {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    // console.log('cleaned: ', cleaned);
  
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  
    if (match) {
      let number = '(' + match[2] + ') ' + match[3] + '-' + match[4];
      // console.log('number: ', number);
      return number;
    } else {
      // Handle case when the phone number doesn't match the expected pattern
      // console.log('Invalid phone number format');
      // You may choose to return the original cleaned number or an empty string
      return cleaned;
    }
  };
  formatPhoneNumber(inputString, callingCodee) {
    // console.log('callingCodee: ', callingCodee);
    // console.log('inputString: ', inputString);
    // let inputString = "9106250875,IN";
  
    // Check if the string includes commas
    if (inputString.includes('+') && inputString.includes('-')) {
      // Split the string into an array using commas as the separator
      // let parts = inputString.split('-');
  
      // // Extract the number and country code
      // let callingCodeWithPlus = parts[0];
      // let callingCode = callingCodeWithPlus.substring(1);
      // let number = parts[1];
  
      // const cleanedNumber = inputString.replace(/[+-]/g, '');
  
      // // Splitting the cleaned phone number
      // const parts = cleanedNumber.split("-");
      // const callingCode = parts[0]; // Calling code
      // const number = parts.slice(1).join("");
  
      const parts = inputString.split("-");
      const callingCodeModify = parts[0].split("+"); // Calling code      
      const callingCode = callingCodeModify[1];
      // console.log("🚀  Profile  callingCode:", callingCode)
  
      const number = parts.slice(1).join(""); // Rest of the number (concatenating all parts except the first)
  
      // console.log("Calling Code:", callingCode);
      // console.log("Rest of Number:", number);
  
      // Do something with the extracted values
      // console.log("Number: " + number);
      // console.log("Country Code: " + callingCode);
  
      // Now you can replace the number and country code as needed
      // For example, let's replace them with "NewNumber" and "NewCountryCode"
      let newNumber = number;
      let newCountryCode = callingCodee;
  
      // Replace the values in the original string
      inputString = inputString.replace(number, newNumber).replace(callingCode, newCountryCode);
      // console.log("Modified String: " + inputString);
      return inputString;
    } else {
      inputString = '+' + callingCodee + '-' + inputString;
      // console.log("🚀  checkPhoneNumber  inputString:", inputString)
      return inputString;
    }
  
  }

  displayNumbers(inputString) {
    // console.log("🚀 ~ extractNumbers ~ inputString:", inputString)
    // Remove 'US/' using String replace  
    if (inputString.includes('+') && inputString.includes('-')) {
      // Remove + and - from inputString      
  
      // Splitting the cleaned phone number
      const parts = inputString.split("-");
      const callingCodeModify = parts[0].split("+"); // Calling code      
      const callingCode = callingCodeModify[1];
      // console.log("🚀 ~ Profile ~ callingCode:", callingCode)
  
      const extractedNumber = parts.slice(1).join(""); // Rest of the number (concatenating all parts except the first)
  
      // console.log("Calling Code:", callingCode);
      // console.log("Rest of Number:", extractedNumber);
      // Extract digits using regular expression
  
      if (extractedNumber) {
        
        return {phoneNumber:this.handlePhoneNumberFormat(extractedNumber), countryCode: callingCode}; 
      }
  
    } else {
      this.phoneNumber = inputString?.replace('US/', '');
  
      const extractedNumber = this.phoneNumber?.replace(/\D/g, '');
      return {phoneNumber:this.handlePhoneNumberFormat(extractedNumber)}; // Return null if no numbers are found in the string
    }
  }


}