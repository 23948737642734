import { Injectable } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class ModelReferenceService {

  constructor() { }

  parentModalRef: BsModalRef;
  childModalRef: BsModalRef;
  secondChildModalRef: BsModalRef;

  clearModalRef() {
    this.secondChildModalRef?.hide();
    this.childModalRef?.hide();
    this.parentModalRef?.hide();
  }

  setParentModalRef(ref: BsModalRef) {
    this.parentModalRef = ref;
  }

  setChildModalRef(ref: BsModalRef) {
    this.childModalRef = ref;
  }

  setSecondChildModalRef(ref: BsModalRef) {
    this.secondChildModalRef = ref;
  }
}
