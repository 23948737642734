import { Directive, HostListener, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAlphabetSpace]'
})
export class AlphabetSpaceDirective {
  private regex: RegExp = new RegExp(/^[a-zA-Z\s]*$/);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;

    // Allow special keys and shortcuts
    if (this.specialKeys.indexOf(event.key) !== -1 || 
        (event.key === 'a' && event.ctrlKey === true) || // Ctrl+A
        (event.key === 'c' && event.ctrlKey === true) || // Ctrl+C
        (event.key === 'v' && event.ctrlKey === true) || // Ctrl+V
        (event.key === 'x' && event.ctrlKey === true)) { // Ctrl+X
      return;
    }

    // Block spaces at the beginning
    if (inputElement.value.length === 0 && event.key === ' ') {
      event.preventDefault();
      return;
    }

    // Block non-alphabetic characters
    const next: string = inputElement.value.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue: string = inputElement.value;

    // Remove any invalid characters if pasted
    if (!this.regex.test(inputValue)) {
      inputElement.value = inputValue.replace(/[^a-zA-Z\s]/g, '');
    }

    // Prevent starting with space (using slice instead of trimStart)
    if (inputElement.value.charAt(0) === ' ') {
      inputElement.value = inputElement.value.slice(1);
    }
  }
}
