export const environment = {
  production: false,
  node_base_url: 'https://node-apperone-chat.agiletechnologies.in/v3/',
  socket_base_url: 'https://node-apperone-chat.agiletechnologies.in',
  base_url: 'https://java-apperone-backend.agiletechnologies.in/api/',
  firebase: {
    apiKey: 'AIzaSyD1mzlvRZDUfTZqNict7ZZoA1wJD9euDkA',
    authDomain: 'chaperone-271619.firebaseapp.com',
    databaseURL: 'https://chaperone-271619.firebaseio.com',
    projectId: 'chaperone-271619',
    storageBucket: 'chaperone-271619.appspot.com',
    messagingSenderId: '32485901774',
    appId: '1:32485901774:web:5166596535289cbd57d79f',
    measurementId: 'G-69H7DW2PQ0',
  },
  stripe_public_key: 'pk_test_51NRfxHD0Xrs6F5sMSeqKrGsmNuhEHMz99RyB33YIrhK1AIEuYl9M7oH5Lcg2U6GNiy7ULPRellZrJhvUvlFWLje1004PZVSJ05'
}