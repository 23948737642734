import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { storedUserData } from '../helpers/const-helper';

@Injectable({providedIn:'root'})
export class canAccess implements CanActivate {

    constructor(
    	private router: Router,
      private permissionsService: NgxPermissionsService,
    ) { }

    canActivate() {
       let user = storedUserData();
      //  let user = JSON.parse(sessionStorage.getItem('userData'));
       if(user) {
           let permissions = Object.keys(this.permissionsService.getPermissions());
           this.permissionsService.loadPermissions([...permissions,user.chUserDTO.userType]);
           return true;
       } else {
        this.router.navigate(['/login'])
        return false;
       }
    }
}
