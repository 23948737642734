// import { Directive, HostListener, Input, Renderer2, ElementRef } from '@angular/core';

// @Directive({
//   selector: '[appNoSpaceMaxLength]'
// })
// export class NoSpaceMaxLengthDirective {
//   @Input() appNoSpaceMaxLength!: number;

//   constructor(private el: ElementRef, private renderer: Renderer2) {}

//   @HostListener('input', ['$event'])
//   onInput(event: Event): void {
//     const inputElement = this.el.nativeElement as HTMLInputElement;
//     let inputValue = inputElement.value;

//     // Remove spaces and limit the length
//     inputValue = inputValue.replace(/\s+/g, '').slice(0, this.appNoSpaceMaxLength);

//     this.renderer.setProperty(inputElement, 'value', inputValue);
//   }
// }

import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNoSpaceMaxLength]'
})
export class NoSpaceMaxLengthDirective {
  @Input() appNoSpaceMaxLength!: number; // Maximum length of the input

  constructor(
    private el: ElementRef, 
    private renderer: Renderer2,
    private ngControl: NgControl // Used for form validation
  ) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue = inputElement.value;

    // Remove spaces and limit the length
    const transformedValue = inputValue.replace(/\s+/g, '').slice(0, this.appNoSpaceMaxLength);

    // Set the transformed value back to the input
    this.renderer.setProperty(inputElement, 'value', transformedValue);

    // Update form control value programmatically
    if (this.ngControl.control) {
      this.ngControl.control.setValue(transformedValue, { emitEvent: false });
    }
  }
}
