<div cdkDropListGroup>
  <app-header></app-header>
  <sidebar></sidebar>
  <main
    [ngClass]="{
    'mainBox': !sidebarToggle || ((user.chUserDTO.userType === 'SUPER_ADMIN') || (user.chUserDTO.userType == 'INCHARGE_ADMIN' && (router.url.split('/').includes('admin-management') || router.url.split('/').includes('settings')))),
    'isSuperAdmin' : ((user.chUserDTO.userType === 'SUPER_ADMIN') ||  (user.chUserDTO.userType == 'INCHARGE_ADMIN' && (router.url.split('/').includes('admin-management') || router.url.split('/').includes('settings'))) || (user.chUserDTO.userType == 'ADMIN' && (router.url.split('/').includes('admin-management') || router.url.split('/').includes('settings'))) ) }"
  >
    <router-outlet class="main-float"></router-outlet>
  </main>
  <settings></settings>
</div>
<ngx-ui-loader class="modelLoader"></ngx-ui-loader>
