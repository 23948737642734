import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { MessagingService } from './services/messaging.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  
  constructor(private router: Router,private messagingService: MessagingService, private toastr: ToastrService) { }
  message;

  ngOnInit() {
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
  }
}
