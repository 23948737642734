import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AdminService {

    constructor(
        public http: HttpClient,
        public router: Router,
    ) { }

    public getAdmins(data) {
        return this.http.get<any>(environment.base_url + "ch-users/admin?page="+data.page+"&size="+data.size+"&sort="+data.sort+","+data.sortType+"&search="+data.search,{ observe: "response" })
            .pipe(map(res => {
                return res;
            }));
    }

    public getUsers(data) {
        return this.http.get<any>(environment.base_url + "ch-user/users?page="+data.page+"&size="+data.size+"&sort="+data.sort+","+data.sortType+"&search="+data.search,{ observe: "response" })
            .pipe(map(res => {
                return res;
            }));
    }
    public addAdmins(data) {
        return this.http.post<any>(environment.base_url +"ch-user/add/admin" ,data)
            .pipe(map(res => {
                return res;
            }));
    }
    public viewAdmin(id) {
        return this.http.get<any>(environment.base_url +"ch-users/"+id)
            .pipe(map(res => {
                return res;
            }));
    }
    public updateAdmin(data) {
        return this.http.post<any>(environment.base_url +"ch-user/update/admin",data)
            .pipe(map(res => {
                return res;
            }));
    }

    public deletUser(id) {
        return this.http.post<any>(environment.base_url +"ch-user/super-admin/user/delete?userIds="+id,{})
    }

    public hardDeletUser(id) {
        return this.http.post<any>(environment.base_url +"ch-user/admin/user/hardDelete?userIds="+id,{})
    }
    public blockAdmin(id) {
        return this.http.post<any>(environment.base_url +"ch-user/admin/block/"+id,{})
    }

    public unBlockAdmin(id) {
        return this.http.post<any>(environment.base_url +"ch-user/admin/unblock/"+id,{})
    }

    public changePassword(data) {
        return this.http.post<any>(environment.base_url +"ch-user/change-password",data)
    }

    public me() {
        return this.http.get<any>(environment.base_url +"ch-user/me")
    }

    public registerAdmin(data) {
        return this.http.post<any>(environment.base_url +"ch-user/admin/register",data)
    }

    public updateUserLimit(data) {
        return this.http.get<any>(environment.base_url +`ch-admin-users/userCount/update?maxUser=${data.count}&id=${data.id}`)
    }


    public checkValidation(data){
        return this.http.get<any>(environment.base_url + `ch-user/user/checkValidation?email=${data.email}&phone=${data.mobile}`,data)
    }
    public impersonateAuth(email: string) {
      return this.http.post<any>(environment.base_url +"ch-user/impersonateAuthenticate", email)
    }

}
