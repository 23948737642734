import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SharedModule } from './shared/shared-module/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { SettingsComponent } from './layout/settings/settings.component';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DataTablesModule } from 'angular-datatables';
import { AuthServices } from '../app/services/auth.service'
import { AdminService } from '../app/services/admin.service'
import { InterceptorService } from './shared/helpers/interceptor'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxPermissionsModule } from 'ngx-permissions';
import { P404Component } from './pages/error/404.component';

import { SidebarService } from './shared/sidebar/sidebar.service';
import { RegisterInfoService } from './shared/register/register.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxImageCompressService } from 'ngx-image-compress';


import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './services/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ImpersonateComponent } from './pages/impersonate/impersonate.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { HeaderComponent } from './layout/header/header.component';
import { SearchBarComponent } from './component/search-bar/search-bar.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    SidebarComponent,
    SettingsComponent,
    AuthLayoutComponent,
    ImpersonateComponent,
    P404Component,
    HeaderComponent,
    SearchBarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    HttpClientModule,
    NgScrollbarModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    TabsModule.forRoot(),
    DataTablesModule,
    SharedModule,
    NgxPermissionsModule.forRoot(),
    Ng2SearchPipeModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxUiLoaderModule,
    NgxIntlTelInputModule,
    NgMultiSelectDropDownModule.forRoot(),
    DragDropModule,
    FullCalendarModule,
    NgxFileDropModule
  ],
  providers: [
    AuthServices,
    SidebarService,
    AdminService,
    NgxImageCompressService,
    RegisterInfoService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    MessagingService, AsyncPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
