import { Component, OnInit } from '@angular/core';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {

  searchValue: string = '';

  constructor(private searchService: SearchService) {
    this.searchService.currentSearchValue.subscribe(value => {
      this.searchValue = value;
    })
  }

  searchValueChange(value: string) {
    this.searchValue = value;
    this.searchService.changeSearchValue(value);
  }

  ngOnInit(): void {
  }

}
