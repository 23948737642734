<div class="search-area">
  <input
    type="text"
    class="input-grp"
    #search
    placeholder="Search"
    [(ngModel)]="searchValue"
    (ngModelChange)="searchValueChange($event)"
  />
</div>
