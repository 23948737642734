
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MessagingService {

  // currentMessage = new BehaviorSubject(null);

  private notifyonMessage = new BehaviorSubject({});
  currentMessage = this.notifyonMessage.asObservable();
  CurrentChatID:number;

  constructor(
    public http: HttpClient,
    private toastr: ToastrService,
    public router: Router,
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
  ) {
    // console.log(this.angularFireMessaging)
    this.angularFireMessaging.messages.subscribe(
      (_messaging:any) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.http.post<any>(environment.base_url + `ch-user/store/web-token?token=${token}`,null).subscribe();
        // this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    
    this.angularFireMessaging.messages.subscribe(
      (payload:any) => {
        // console.log("new message received. ", payload);
        if(!this.CurrentChatID){
          this.toastr.info( payload.notification.body, payload.notification.title,{
            // toastClass: "toast-custom"
          });
        }else{
          if(this.CurrentChatID != payload.data.id){
            this.toastr.info( payload.notification.body, payload.notification.title,{
              // toastClass: "toast-custom"
            });
          }
        }
        // this.swalAlert(payload);
        // console.log(this.notifyonMessage)
        // this.notifyonMessage.next(payload);
      })
  }

  set setChatID(id){
    this.CurrentChatID = id;
  }

  // swalAlert(payload){
  //   const Toast = Swal.mixin({
  //     toast: true,
  //     position: 'top-end',
  //     showConfirmButton: false,
  //     timer: 3000,
  //     timerProgressBar: true,
  //     onOpen: (toast) => {
  //       toast.addEventListener('mouseenter', Swal.stopTimer)
  //       toast.addEventListener('mouseleave', Swal.resumeTimer)
  //     }
  //   })
    
  //   Toast.fire({
  //     icon: 'success',
  //     title: payload.notification.title,
  //     text: payload.notification.body,
  //   })
  // }
  
  // alertNotification(){
  //   return this.currentMessage;
  // }
}
