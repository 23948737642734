import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
