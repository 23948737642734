import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { AppInfoService } from '../../services/appinfo.service';
import { UsersService } from '../../services/users.service';
import { TripService } from '../../services/trip.service';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';
import { NgxImageCompressService } from 'ngx-image-compress';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { MiscService } from 'src/app/services/misc.service';
import { PhoneNumberService } from 'src/app/services/phonenumber-format';
import { ModelReferenceService } from 'src/app/shared/model-reference.service';
import {
  storedUserData,
  tripsidebarData,
  tripsidebarDataArchive,
} from 'src/app/shared/helpers/const-helper';
import { parsePhoneNumber } from 'libphonenumber-js';
import { event } from 'jquery';
import { BehaviorSubject, interval } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  shouldShow: boolean = false;
  modalSettings: BsModalRef;
  modalAccount: BsModalRef;
  modalLicense: BsModalRef;
  modalChangepassword: BsModalRef;
  modalVersionEntry: BsModalRef;
  isType: boolean = false;
  showMenu: boolean = false;
  showInnerMenu: boolean = false;
  modalAbout: BsModalRef;
  modalPrivacy: BsModalRef;
  modalTerms: BsModalRef;
  modalNotifications: BsModalRef;
  modalGroupname: BsModalRef;
  modalFolder: BsModalRef;
  superAdmin = false;
  inchargeAdmin = false;
  subAdmin = false;
  subAdminAsInchargeAdmin = false;
  changePasswordForm: FormGroup;
  updateMobileVersionForm: FormGroup;
  updateMobileVersionSubmited: boolean = false;
  createEmptyFolderForm: FormGroup;
  resetPasswordSubmited: boolean = false;
  adminDetail: any = {
    imageUrl: 'assets/images/profile-placeholder-image.svg',
  };
  aboutDetail: string = '';
  privacyDetail: string = '';
  termsDetail: string = '';
  editAdminForm: FormGroup;
  profileImageFile: any = null;
  selectedImage: any = 'assets/images/profile-placeholder-image.svg';
  profileSumitted: boolean = false;
  isHidden = false;
  isEditing: boolean = false;

  manageActive: boolean = true;
  archiveActive: boolean = false;
  addNewActive: boolean = false;
  databaseActive: boolean = false;
  addTripActive: boolean = false;
  subAdminActive: boolean = false;

  tripsidebarData = tripsidebarData;
  tripsidebarDataArchive = tripsidebarDataArchive;
  modalList: BsModalRef;
  userType: string;
  modalAttendees: BsModalRef;
  createListForm: FormGroup;
  adminAsLeader: boolean = false;
  isListSubmitted: boolean = false;
  listType: string[] = ['Team', 'Room', 'Bus', 'Custom List'];
  dropdownSettings = {
    singleSelection: true,
    placeholder: 'Select a List Type',
    closeDropDownOnSelection: true,
  };
  listName: string = 'Name';
  listNamePlaceholder: string = 'Give a unique name';
  submitButton: string = 'Create List';
  showAssignTeamView: boolean = false;
  selectedTripOfficial = [];
  isAllTeamOfficialSelected: boolean = false;
  attendieeSearchSource = new BehaviorSubject('');
  attendeesSearchObserve = this.attendieeSearchSource.asObservable();
  textInvitesSearchLoading: boolean = false;
  reloadAttendeesTable: boolean = true;
  searchValuesOfTab;
  dtOptions: DataTables.Settings;
  teamAttendeesUsers: any = [];
  tripID: string = '';

  newTripStatus: number = 0;
  tripListing = [];
  Grouplisting = [];

  groupname: string = '';
  groupnameadd: string = '';
  @ViewChild('addgroupname') addgroupname: ElementRef;
  groupID: number;
  groupsubmitted: boolean = false;
  groupsubmittedupdate: boolean = false;
  Folders: any = [];
  GroupButtonName: string = 'Add New';
  ExistingGroupUsers: any = [];
  GroupUsers: any = [];

  queryParams: any = {};
  currentTrip: any = {};
  dropdownList = [];
  subAdminList = [];
  selectedSubAdmin : any= [];
  optionsLabel = [
    { value: 'read', label: 'View Only', icon: 'assets/images/info-icon.svg' , tooltip : 'Access trip and user info, view itineraries, chats, and photos without editing capabilities' },
    { value: 'write', label: 'Editor' ,tooltip : 'Access trip and user info, view itineraries, chats, and photos with editing capabilities' },
  ];
  selectedOption = { value: 'read', label: 'View Only', icon: 'assets/images/info-icon.svg',tooltip : 'Access trip and user info, view itineraries, chats, and photos without editing capabilities'  };
  userRole:any;
  hideSidebarForSubAdmin=false;

  AdminNotifications: any = [];
  @ViewChild('groups', { static: true }) groups: TemplateRef<any>;
  @ViewChild('folder', { static: true }) folder: TemplateRef<any>;

  currentTripOptionDisplay: string = null;
  isCreateEmptyFolderSubmitted: boolean = false;
  folderbutton: string = 'Save';
  folderID: number;
  SelectedGroupIDForUsers: number;

  show: boolean = false;

  showCurrentPassword: boolean = false;

  showNewPassword: boolean = false;

  showConfirmPassword: boolean = false;

  countryCode: any = [];
  selectedCountryCode: string = '1';
  defaultCode: string = '1';
  phoneModel: any;
  uuid: string;

  public url: any;
  selectedFileType: any = '';
  filesSelectName = '';
  public selectedFile: any;
  fileToUpload: any;
  paramGroupId: any;
  user = storedUserData();
  listIndex: number = -1;
  listLoader: boolean = false;
  archieveTripCount: number = 0;
  archieveShow: boolean = true;

  constructor(
    public router: Router,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private adminService: AdminService,
    private toastr: ToastrService,
    private appInfoService: AppInfoService,
    private sidebarService: SidebarService,
    private userserive: UsersService,
    private ngxLoader: NgxUiLoaderService,
    private tripservice: TripService,
    private activatedRoute: ActivatedRoute,
    private imageCompress: NgxImageCompressService,
    private miscService: MiscService,
    private modalRefService: ModelReferenceService,
    private phoneNumberService: PhoneNumberService,
    private cdr: ChangeDetectorRef,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.groupId) {
        this.paramGroupId = params.groupId;
      } else {
        this.paramGroupId = null;
      }

      const tripIndex = this.tripListing.findIndex(
        (trip) => trip.chTrip.id == params.trip
      );
      if (
        params.trip &&
        params.trip != this.queryParams.trip &&
        tripIndex < 0
      ) {
        tripIndex > 0 ? null : (this.tripListing = []);
        const archieveData = {
          page: 0,
          size: 1000,
          status: 'ARCHIVED',
          search: '',
        };
        this.tripservice.getTripListing({ ...archieveData }).subscribe({
          next: (response) => {
            this.sidebarService.setArchieveTripCount(response.body.data.total);
          },
          error: (err) => {
            console.log(err);
          },
        });
        this.tripservice.getTripDetails(params.trip).subscribe((response) => {
          this.currentTrip = response.data;
          if(response.data.tripStatus == "ARCHIVED") {
            this.changeSidebartwo("archive");
          }
        });
      }
    });
  }

  openMenu() {
    this.showMenu = !this.showMenu;

    const menuElement = document.querySelector('.addArrow'); // Or use document.querySelector('.menu') for a class
    if (this.showMenu) {
      menuElement.classList.add('open'); // Add the 'open' class
    } else {
      this.showInnerMenu = false;
      // const menuElement1 = document.querySelector('.ul-list-click');
      menuElement.classList.remove('open');
      // menuElement1?.classList?.remove('open'); // Remove the 'open' class
    }
  }

  changeInnerMenu(index: number, event) {
    event.preventDefault();
    this.showInnerMenu = !this.showInnerMenu;
    if (this.listIndex == index) {
      this.listIndex = -1;
    } else {
      this.listIndex = index;
    }
  }

  openMenuInner(listUuid: string) {
    // const menuElement = document.querySelector('.ul-list-click-' + index); // Or use document.querySelector('.menu') for a class
    let uuid = '';
    this.activatedRoute.queryParams.subscribe((params) => {
      uuid = params.uuid ? params.uuid : this.uuid;
    });
    if (uuid == listUuid) {
      this.uuid = listUuid;
      this.showInnerMenu = true;
      // menuElement.classList.add('open'); // Add the 'open' class
      return true;
    } else {
      // menuElement.classList.remove('open'); // Remove the 'open' class
      return false;
    }
  }

  attendeesDropped(dropData, id: string, name: string) {
    if (name.toLowerCase() == 'default team') {
      this.toastr.warning('You can not add attendees to the default team.', 'Warning');
      return;
    }
    const attendees = dropData.previousContainer.data.data.map((element) => {
      return element.tripAttendeeId;
    });
    this.ngxLoader.start();
    this.tripservice
      .addAttendeesToList({
        attendeesIds: attendees,
        id,
      })
      .subscribe({
        next: (response) => {
          this.ngxLoader.stop();
          this.toastr.success;
          if (response.status == 400) {
            this.toastr.error(response.message, 'Error');
          } else if (response && response.code && response.code == 400) {
            this.toastr.error(response.message, 'Error');
          } else {
            this.toastr.success(response.message, 'Success');
          }
          dropData.previousContainer.data.function();
        },
        error: (err) => {
          console.log(err);
          this.toastr.error('Something went wrong', 'Error');
        },
      });
  }

  settingsModal(settings: TemplateRef<any>) {
    this.modalSettings = this.modalService.show(
      settings,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    this.modalRefService.setParentModalRef(this.modalSettings);
  }
  accountModal(account: TemplateRef<any>) {
    this.isHidden = true;
    this.modalAccount = this.modalService.show(
      account,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    if (this.adminDetail.imageUrl) {
      this.selectedImage = this.adminDetail.imageUrl;
    }
    this.modalRefService.setChildModalRef(this.modalAccount);
  }
  licenseModal(license: TemplateRef<any>) {
    this.modalLicense = this.modalService.show(
      license,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    this.modalRefService.setChildModalRef(this.modalLicense);
  }
  changepasswordModal(changepassword: TemplateRef<any>) {
    this.modalChangepassword = this.modalService.show(
      changepassword,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    this.modalRefService.setChildModalRef(this.modalChangepassword);
  }
  mobileVersionModel(versionEntry: TemplateRef<any>) {
    this.modalVersionEntry = this.modalService.show(
      versionEntry,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    this.modalRefService.setChildModalRef(this.modalVersionEntry);
  }
  aboutModal(about: TemplateRef<any>) {
    this.getAbout();
    this.modalAbout = this.modalService.show(
      about,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    this.modalRefService.setChildModalRef(this.modalAbout);
  }
  privacyModal(privacy: TemplateRef<any>) {
    this.getPrivacy();
    this.modalPrivacy = this.modalService.show(
      privacy,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    this.modalRefService.setChildModalRef(this.modalPrivacy);
  }
  termsModal(terms: TemplateRef<any>) {
    this.getTerms();
    this.modalTerms = this.modalService.show(
      terms,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    this.modalRefService.setChildModalRef(this.modalTerms);
  }
  notificationsModal(notifications: TemplateRef<any>) {
    this.getAllNotifications();
    this.modalNotifications = this.modalService.show(
      notifications,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    this.modalRefService.setChildModalRef(this.modalNotifications);
  }
  groupnameModal(groups: TemplateRef<any>) {
    this.modalGroupname = this.modalService.show(
      groups,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    this.modalRefService.setChildModalRef(this.modalGroupname);
  }

  selectPlatform(platform: 'ANDROID' | 'IOS') {
    this.updateMobileVersionForm.patchValue({ platform });
  }

  setForceUpdate(forceUpdate: boolean) {
    this.updateMobileVersionForm.patchValue({ forceUpdate });
  }

  setApplicationName(applicationName: 'Apperone' | 'Apperone Pro') {
    this.updateMobileVersionForm.patchValue({ applicationName });
  }

  updateMobileVersion() {
    this.updateMobileVersionSubmited = true;
    if (!this.updateMobileVersionForm.valid) return;
    const formValue = this.updateMobileVersionForm.value;
    const requestObject = {
      appVersionName: formValue.appVersion,
      deviceOs: formValue.platform,
      appName: formValue.applicationName,
      forceUpdate: formValue.forceUpdate,
      active: true,
      appVersionCode: 123465,
      description: formValue.appVersion,
    };
    this.userserive.mobileVersionUpdate(requestObject).subscribe({
      next: (response) => {
        this.updateMobileVersionSubmited = false;
        if (response.code == 400) {
          this.toastr.error(response.message, 'Error');
        } else {
          this.toastr.success(response.message, 'Success');
          this.updateMobileVersionForm.reset();
          this.modalVersionEntry.hide();
        }
      },
      error: (err) => {
        console.log(err);
        this.toastr.error('Unable to add version', 'Error');
      },
    });
    // console.log(requestObject);
  }

  closeVersionUpdateModel() {
    this.updateMobileVersionForm.reset();
    this.updateMobileVersionSubmited = false;
    this.modalVersionEntry.hide();
  }

  /*---------------------
    | Folder Listing
    ----------------------*/
  folderlisting(folder: TemplateRef<any>) {
    this.modalFolder = this.modalService.show(
      folder,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    this.modalRefService.setParentModalRef(this.modalFolder);
  }
  currentUserData: any;
  data: any;

  checkSession() {
    return sessionStorage.getItem('userData') ? false : true;
  }

  createListModal(listTemplate: TemplateRef<any>, tripId) {
    this.listName = 'Name';
    this.listNamePlaceholder = 'Give a unique name';
    this.submitButton = 'Create List';
    this.showAssignTeamView = false;
    this.getTeamAttendence();
    this.tripID = tripId;
    this.selectedTripOfficial = [];
    this.isListSubmitted = false;
    this.createListForm.get('tripId').patchValue(tripId);
    this.modalList = this.modalService.show(
      listTemplate,
      Object.assign(
        {},
        { class: 'modal-lg createList-modal modal-dialog-centered' }
      )
    );
    this.modalRefService.setParentModalRef(this.modalList);
  }

  attendeesModal(attendees: TemplateRef<any>) {
    this.userType = 'TRIP_OFFICIAL';
    this.modalAttendees = this.modalService.show(
      attendees,
      Object.assign({}, { class: 'modal-xl modal-dialog-centered' })
    );
    this.modalRefService.setChildModalRef(this.modalAttendees);
  }

  onItemSelect(value) {
    this.adminAsLeader = false;
    this.createListForm.get('listType').patchValue(value);
    switch (value) {
      case 'Team':
        this.listName = 'Team Name';
        this.listNamePlaceholder = 'Give a unique name (e.g Team)';
        this.submitButton = 'Add Team';
        this.showAssignTeamView = true;
        break;
      case 'Room':
        this.listName = 'Room Name';
        this.listNamePlaceholder = 'Give a unique name (e.g Room)';
        this.submitButton = 'Add Room';
        this.showAssignTeamView = false;
        break;
      case 'Bus':
        this.listName = 'Bus Name';
        this.listNamePlaceholder = 'Give a unique name (e.g Bus)';
        this.submitButton = 'Add Bus';
        this.showAssignTeamView = false;
        break;
      case 'Custom List':
        this.listName = 'Custom List Name';
        this.listNamePlaceholder = 'Give a unique name (e.g Custom List)';
        this.submitButton = 'Add Custom List';
        this.showAssignTeamView = false;
        break;
      default:
        break;
    }
  }

  closeModel() {
    this.listName = 'Name';
    this.listNamePlaceholder = 'Give a unique name';
    this.submitButton = 'Create List';
    this.showAssignTeamView = false;
    this.adminAsLeader = false;
    this.createListForm.reset();
    this.isListSubmitted = false;
    this.modalList.hide();
  }

  markAdminTeamMember(event) {
    if (event.target.checked) {
      this.adminAsLeader = true;
    } else {
      this.adminAsLeader = false;
    }
  }

  searchChanges(event) {
    this.attendieeSearchSource.next(event.target.value);
  }

  detectSearch() {
    this.attendeesSearchObserve
      .pipe(
        map((value: any) => value),
        debounce(() => interval(800)),
        distinctUntilChanged()
      )
      .subscribe((response) => {
        this.searchValuesOfTab = response;
        this.getTeamAttendence();
        this.reloadAttendeesTable = false;
        this.textInvitesSearchLoading = true;
        setTimeout(() => {
          this.reloadAttendeesTable = true;
        }, 0);
      });
  }

  formatUsertype(string: string) {
    if (string == 'ADMIN') {
      return 'Sub Admin';
    }
    string = string.toLowerCase();
    let words = string.split('_');
    words.forEach(function (item, index) {
      words[index] = item.replace(item[0], item[0].toUpperCase());
    });
    return words.join(' ');
  }

  isTeamOfficalAlreadySelected(official) {
    return this.selectedTripOfficial.some(
      (selectedOfficial) =>
        selectedOfficial.tripAttendeeId == official.tripAttendeeId
    );
  }

  selectAllTeamOfficial(officialList) {
    if (!this.isAllTeamOfficialSelected) {
      officialList.forEach((official) => {
        if (
          !this.selectedTripOfficial.some(
            (selectedOfficial) =>
              selectedOfficial.tripAttendeeId == official.tripAttendeeId
          )
        ) {
          this.selectedTripOfficial.push(official);
        }
      });
    } else {
      officialList.forEach((official) => {
        if (
          this.selectedTripOfficial.some(
            (selectedOfficial) =>
              selectedOfficial.tripAttendeeId == official.tripAttendeeId
          )
        ) {
          this.selectedTripOfficial = this.selectedTripOfficial.filter(
            (selectedOfficial) =>
              selectedOfficial.tripAttendeeId != official.tripAttendeeId
          );
        }
      });
    }
  }

  getTeamAttendence() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        let page = Math.floor(
          dataTablesParameters.start / dataTablesParameters.length
        );
        let size = dataTablesParameters.length;
        let sort =
          dataTablesParameters.columns[dataTablesParameters.order[0].column]
            .data == null
            ? 'firstName'
            : dataTablesParameters.columns[dataTablesParameters.order[0].column]
                .data;
        let sortType = dataTablesParameters.order[0].dir;
        let search = this.searchValuesOfTab ? this.searchValuesOfTab : '';
        let data = {
          page: +page,
          size: +size,
          sort: sort,
          sortType: sortType,
          search: search,
        };
        this.teamAttendeesUsers = [];
        const teamID = '';
        this.isAllTeamOfficialSelected = false;
        this.tripservice
          .getTripAttendees({
            ...data,
            userType: this.userType,
            teamId: teamID,
            tripId: this.tripID,
          })
          .subscribe((response) => {
            this.teamAttendeesUsers = response.data.tripAttendees.map(
              (user) => {
                user.type = this.formatUsertype(user.type);
                return user;
              }
            );
            this.selectOfficial(null, this.teamAttendeesUsers);
            this.textInvitesSearchLoading = false;
            callback({
              recordsTotal: response.data.total,
              recordsFiltered: response.data.total,
              data: [],
            });
          });
      },
      columns: [
        { data: null, orderable: false },
        { data: 'firstName' },
        { data: 'email' },
        { data: 'type', orderable: false },
      ],
    };
  }

  selectOfficial(official, currentList) {
    if (!official) {
      if (!this.selectedTripOfficial.length) {
        return;
      }
      if (
        currentList.every((user) =>
          this.selectedTripOfficial.some(
            (official) => official.tripAttendeeId == user.tripAttendeeId
          )
        )
      ) {
        this.isAllTeamOfficialSelected = true;
      }
      return;
    }

    let alreadyPresent = this.selectedTripOfficial.some(
      (selectedOfficial) =>
        selectedOfficial.tripAttendeeId == official.tripAttendeeId
    );
    if (alreadyPresent) {
      this.selectedTripOfficial = this.selectedTripOfficial.filter(
        (selectedOfficial) =>
          selectedOfficial.tripAttendeeId != official.tripAttendeeId
      );
      this.isAllTeamOfficialSelected = false;
    } else {
      this.selectedTripOfficial.push(official);
      if (
        currentList.every((user) =>
          this.selectedTripOfficial.some(
            (official) => official.tripAttendeeId == user.tripAttendeeId
          )
        )
      ) {
        this.isAllTeamOfficialSelected = true;
      }
    }
  }

  closeAttendees() {
    this.selectedTripOfficial = this.selectedTripOfficial.filter(
      (ele) => ele.alreadyAdded
    );
    this.modalAttendees.hide();
  }

  checkActiveList(listId: string) {
    let id = '';
    this.activatedRoute.queryParams.subscribe((params) => {
      id = params.id ? params.id : params.uuid;
    });
    if (listId == id) {
      return true;
    } else {
      return false;
    }
  }

  onListSubmit() {
    this.isListSubmitted = true;
    const formValue = this.createListForm.value;
    if (this.createListForm.valid) {
      // if (formValue.listType == 'Team' || formValue.listType == 'TEAM') {
      //   if (!this.selectedTripOfficial.length && !this.adminAsLeader) {
      //     this.toastr.warning(
      //       'Please select at least one team leader.',
      //       'warning'
      //     );
      //     return;
      //   }
      //   formValue.listAttendees = this.selectedTripOfficial.map(
      //     (attendees) => attendees.tripAttendeeId
      //   );
      // }
      switch (formValue.listType) {
        case 'Team':
          formValue.listType = 'TEAM';
          break;
        case 'Room':
          formValue.listType = 'ROOM';
          break;
        case 'Bus':
          formValue.listType = 'BUS';
          break;
        case 'Custom List':
          formValue.listType = 'CUSTOM';
          break;
        default:
          break;
      }
      const createObj = {
        listType: formValue.listType,
        name: formValue.listName,
        qty: formValue.quantity,
        tripId: formValue.tripId,
      };
      if ((formValue.listType = 'TEAM' && Number(formValue.quantity) <= 0)) {
        return;
      }
      this.ngxLoader.start();
      this.listLoader = true;
      this.tripservice.createBulkList(createObj).subscribe({
        next: (response) => {
          this.ngxLoader.stop();
          if (response && response.code && response.code == 400) {
            this.listLoader = false;
            this.toastr.error(response.message, 'Error');
          } else {
            const findTripIndex = this.tripListing.findIndex(
              (trip) => trip.chTrip?.id == formValue.tripId
            );
            if (findTripIndex >= 0) {
              this.tripListing[findTripIndex].chTrip.lists =
                response.data.lists;
              this.sidebarService.setSideBarTrips(this.tripListing);
            }
            if (this.currentTrip.id == formValue.tripId) {
              this.currentTrip.lists = response.data.lists;
              this.sidebarService.setcurrentTrip(this.currentTrip);
            }
            this.listLoader = false;
            this.closeModel();
            this.sidebarService.setReloadTableFun(true);
          }
        },
        error: (err) => {
          this.listLoader = false;
          console.log(err);
        },
      });
    }
  }

  billingData(){
    localStorage.setItem('settingTag', "Billing");
  }
  hideFunctionalityForSubAdmin() {
    if(this.userRole?.userType == "ADMIN") {
      let selectedAdmin = this.selectedSubAdmin.find(data => data.user_id == this.userRole?.id)
      if(selectedAdmin?.type) {
        if(selectedAdmin?.type?.value == "write") {
          this.hideSidebarForSubAdmin = true
        } else {
          this.hideSidebarForSubAdmin = false
        }
      } else  {
        this.hideSidebarForSubAdmin = true
      }
    } else {
      this.hideSidebarForSubAdmin = true
    }
  }
  getTripDetailsForHideShow() {
    this.tripservice.getTripDetails(this.queryParams.trip).subscribe((response) => {
      if (response.data.subAdmin) {
          this.subAdminList = this.dropdownList.filter(allAdmin => {
              const matchedAdmin = response.data.subAdmin.find(s => allAdmin.item_id === s.chTripAttendeesId);
              if (matchedAdmin) {
                  let type = this.optionsLabel.find(o=> o.value == matchedAdmin.accessType)
                  allAdmin.type = type ? type : this.selectedOption //  matchedAdmin.accessType;
                  allAdmin.isDropdownOpen = false// Assign the type property
                  return true;
              }else{
                  allAdmin.type = this.selectedOption;
              }
              response.data.subAdmin.some(selectedAdmins => allAdmin.item_id === selectedAdmins.chTripAttendeesId)
          });
          response.data.subAdmin.forEach(element => {
              const index = this.dropdownList.findIndex(
                  (item) => item.item_id === element.chTripAttendeesId
              );
              this.dropdownList.splice(index, 1);
          });
          this.selectedSubAdmin = this.subAdminList;
          this.hideFunctionalityForSubAdmin()
      }
    });

  }

  getSubAdminList() {
    this.ngxLoader.start();
    let data = {
        page: 0,
        size: 100,
        sort: 'firstName',
        sortType: 'asc',
        search: '',
    };
    this.tripservice
        .getTripAttendees({
            ...data,
            userType: 'ADMIN',
            teamId: '',
            tripId: this.queryParams.trip,
        })
        .subscribe((response) => {
            let tmp = [];
            for (let index = 0; index < response.data.tripAttendees.length; index++) {
                tmp.push({
                    item_id: response.data.tripAttendees[index].tripAttendeeId,
                    item_text: response.data.tripAttendees[index].firstName + ' ' + response.data.tripAttendees[index].lastName,
                    item_image: response.data.tripAttendees[index].image,
                    item_email: response.data.tripAttendees[index].email,
                    user_id:response.data.tripAttendees[index].userId,
                    type: 'read'
                });

            }
            this.dropdownList = tmp;
            this.getTripDetailsForHideShow()
            this.ngxLoader.stop();
        });
}

  ngOnInit() {
    // localStorage.removeItem('createTripData')
    // start * 29-8-2023 => This is to redirect the sub sidebar to info portion after adding new trip
    this.sidebarService.sidebarOption.subscribe((option) => {
      this.changeSidebartwo(option);
    });
    // end * 29-8-2023 => This is to redirect the sub sidebar to info portion after adding new trip
    let userData = storedUserData();
    this.userRole = userData.chUserDTO; // Assume this.userType holds the current user role

    this.userserive.getCurrentUserInfo().subscribe((response) => {
      this.data = { ...this.adminDetail, ...response.data };

      this.adminDetail = { ...this.data };
    });

    if (this.adminDetail.imageUrl) {
      this.selectedImage = this.adminDetail.imageUrl;
    }
    if (!this.adminDetail.imageUrl) {
      this.adminDetail.imageUrl = 'assets/images/profile-placeholder-image.svg';
    }
    if (userData.chUserDTO.userType == 'SUPER_ADMIN') {
      this.superAdmin = true;
      this.subAdminAsInchargeAdmin = true;
    } else {
      this.superAdmin = false;
    }
    if (userData.chUserDTO.userType == 'INCHARGE_ADMIN') {
      this.inchargeAdmin = true;
      this.shouldShow = true;
      // this.router.url == '/admin-management/list-administrator' ? this.shouldShow = false : null;
    }
    if (userData.chUserDTO.userType == 'ADMIN') {
      this.subAdmin = true;
    }
    //   if("INCHARGE_ADMIN" === "INCHARGE_ADMIN" ){
    //     console.log("INCHARGE_ADMININCHARGE_ADMININCHARGE_ADMININCHARGE_ADMININCHARGE_ADMININCHARGE_ADMIN");

    //     this.inchargeAdmin = true
    // }
    // else{

    //     this.inchargeAdmin = false
    // }
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.queryParams = params;
    });

    // this.changePasswordForm = this.fb.group({
    //   currentPassword: ['', Validators.required, this.noWhitespaceValidator],
    //   newPassword: ['', Validators.required, this.noWhitespaceValidator],
    //   confirmPassword: ['', Validators.required, this.noWhitespaceValidator],
    // });

    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required, this.noWhitespaceValidator]],
      newPassword: ['', [Validators.required, this.noWhitespaceValidator]],
      confirmPassword: ['', [Validators.required, this.noWhitespaceValidator]],
    });

    this.updateMobileVersionForm = this.fb.group({
      platform: [null, [Validators.required]],
      appVersion: [null, [Validators.required]],
      forceUpdate: [null, [Validators.required]],
      applicationName: [null, [Validators.required]],
    });

    this.createListForm = this.fb.group({
      listType: ['', [Validators.required]],
      listName: [
        '',
        [Validators.required, Validators.pattern(/^[A-Z0-9]+( [A-Z0-9]+)*$/i)],
      ],
      quantity: ['', [Validators.required, Validators.pattern(/^[0-9]{0,}$/i)]],
      listAttendees: [],
      tripId: [],
    });
    this.detectSearch();

    this.changePasswordForm.valueChanges.subscribe(() => {
      // this.validateForm();
    });

    // this.editAdminForm = this.fb.group({
    //   email: ['', Validators.required],
    //   firstName: ['', Validators.required],
    //   lastName: ['', Validators.required],
    //   organization: ['', Validators.required],
    //   phone: ['', [Validators.required, Validators.minLength(14)]],
    //   position: ['', Validators.required],
    // });
    // console.log('this.adminDetail: patchValue', this.adminDetail);
    // this.editAdminForm.patchValue(this.adminDetail);

    this.sidebarService.getTripStatus().subscribe((status) => {
      this.newTripStatus = status;
    });

    this.sidebarService.getSideBarTrips().subscribe((data) => {
      this.tripListing = data;
      // this.getFolders();
    });

    // this.getArchieveCount();
    this.sidebarService.getArchieveTripCount().subscribe((count) => {
      this.archieveTripCount = count;
    });

    this.sidebarService.getSideBarFolders().subscribe((data) => {
      this.Folders = data;
    });

    this.sidebarService.getSideBarGroups().subscribe((data) => {
      //console.log("data",data)
      this.groupsubmitted = false;
      this.Grouplisting = data;
    });
    //16-05-2023 => to keep close the drawer beside he side bar while Incharge Admin get logIn
    // this.setSidebarOption();

    this.sidebarService.getOpenTripSidebarOption().subscribe((id) => {
      this.openTripOption(id);
    });

    if (this.queryParams.trip && !this.tripListing.length) {
      this.getSubAdminList()
      this.tripservice
        .getTripDetails(this.queryParams.trip)
        .subscribe((response) => {
          this.currentTrip = response.data;
        });
      this.sidebarService.getcurrentTrip().subscribe((data) => {
        this.currentTrip = { ...this.currentTrip, ...data };
      });
    }

    this.createEmptyFolderForm = this.fb.group({
      folderName: ['', [Validators.required]],
    });

    //sprint 6
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.queryParams = params;
      if (params.isComplete && params.trip) {
        this.updateTripStatus();
      }
    });
    //sprint 6

    this.miscService.getCountryCode().subscribe((response) => {
      this.countryCode = response.data;
    });
  }

  // getArchieveCount() {
  //   const data = {
  //     page: 0,
  //     size: 1000,
  //     status: 'ARCHIVED',
  //     search: '',
  //   }
  //   this.tripservice.getTripListing({ ...data }).subscribe({
  //     next: (response) => {
  //       this.archieveShow = true;
  //       this.archieveTripCount = response.body.data.total;
  //     },
  //     error: (err) => {
  //       console.log(err);
  //     }
  //   });
  // }

  ngAfterViewInit() {
    this.editAdminForm = this.fb.group({
      email: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      organization: ['', Validators.required],
      phone: ['', Validators.required],
      position: ['', Validators.required],
      countryCode: [''],
    });

    this.userserive.getCurrentUserInfo().subscribe((response) => {
      response.data.phone = response.data.phone.includes('+')
        ? response.data.phone
        : '+1' + response.data.phone;
      const { countryCallingCode, nationalNumber } = parsePhoneNumber(
        response.data?.phone
      );
      response.data.countryCode = countryCallingCode ? countryCallingCode : '1';
      response.data.phone = nationalNumber;
      this.adminDetail = { ...this.adminDetail, ...response.data };
      this.patchForm();
    });
  }

  patchForm() {
    if (this.adminDetail) {
      // console.log('this.adminDetail: ', this.adminDetail);
      this.data = this.phoneNumberService.displayNumbers(
        this.adminDetail.phone
      );
      // console.log('this.data: ', this.data);
      this.editAdminForm.patchValue({
        email: this.adminDetail.email,
        firstName: this.adminDetail.firstName,
        lastName: this.adminDetail.lastName,
        organization: this.adminDetail.organization,
        phone: this.adminDetail.phone,
        position: this.adminDetail.position,
        countryCode: this.adminDetail.countryCode,
      });
    }
  }
  openTripOption(id) {
    this.currentTripOptionDisplay = id;
  }

  setDefaultCode(code) {
    return code == this.defaultCode;
  }

  countryCodeChange(event) {
    this.selectedCountryCode = event.target.value;
  }

  addnewGroup(type) {
    // if(this.groupname != ''){
    if (type == 'add') {
      this.groupsubmitted = true;
      if (this.groupname.trim() != '') {
        this.ngxLoader.start();
        const groupvalue = { chUserId: [], groupName: this.groupname };
        this.userserive.addNewGroup(groupvalue).subscribe((option) => {
          this.toastr.success(option.message, 'Success');
          this.ngxLoader.stop();
          this.groupsubmitted = false;
          this.groupname = '';
          this.addgroupname.nativeElement.value = '';
          this.getGroupListing();
        });
      }
    } else {
      this.groupsubmittedupdate = true;
      if (this.groupnameadd != '') {
        this.ngxLoader.start();
        const groupvalue = { groupName: this.groupnameadd, id: this.groupID };
        this.userserive.updatefolderName(groupvalue).subscribe((option) => {
          this.toastr.success(option.message, 'Success');
          this.ngxLoader.stop();
          this.groupsubmittedupdate = false;
          this.modalGroupname.hide();
          this.getGroupListing();
        });
      }
    }
    // }
  }

  // createEmptyFolder() {
  //     this.sidebarService.setEmptyFolder({status:true});
  // }

  getGroupListing() {
    this.userserive.getAllGroups().subscribe((res) => {
      this.Grouplisting = res.data;
      this.sidebarService.setSideBarGroupsFromSideBar(res.data);
    });
  }

  /* Edit Group Details */
  // editGroup(group){
  //     this.GroupButtonName = 'Update Group'
  //     this.groupname = group.groupName;
  //     this.groupID = group.id;
  // }

  /* Edit Group Details */
  editGroupName(group) {
    this.GroupButtonName = 'Update Group';
    this.groupnameadd = group.groupName;
    this.groupID = group.id;
    this.modalGroupname = this.modalService.show(
      this.groups,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    this.modalRefService.setParentModalRef(this.modalGroupname);
    // this.groupnamemodal.show();
  }

  /* Delete Group*/
  deleteGroup(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this group ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.ngxLoader.start();
        this.userserive.deleteGroup(id).subscribe((option) => {
          this.toastr.success(option.message, 'Success');
          if (this.paramGroupId) {
            if (this.paramGroupId == id) {
              this.router.navigate(['/database']);
            } else {
              this.router.navigate(['/database/group'], {
                queryParams: { groupId: this.paramGroupId },
              });
            }
          } else {
            this.router.navigate(['/database']);
          }
          // this.getGroupUsers(id,event)
          this.sidebarService.setGroupStatusOption(true);
          this.ngxLoader.stop();
          this.getGroupListing();
        });
      }
    });
  }

  removeUserFromGroup(user) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want remove selected user from this group ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.ngxLoader.start();
        this.userserive
          .removeUserFromGroup({
            user_id: user.id,
            group_id: this.SelectedGroupIDForUsers,
          })
          .subscribe((option) => {
            this.toastr.success(option.message, 'Success');
            this.sidebarService.setGroupStatusOption(true);
            this.ngxLoader.stop();
            this.getGroupListing();
          });
      }
    });
  }

  /* Edit Group Details */
  editFolder(folder) {
    // const data = {status:true,data:folder};
    if (folder && Object.keys(folder).length) {
      this.folderID = folder.id;
      this.createEmptyFolderForm.patchValue({
        folderName: folder.name,
      });
      this.folderbutton = 'Update';
      this.modalFolder = this.modalService.show(
        this.folder,
        Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
      );
      this.modalRefService.setParentModalRef(this.modalFolder);
    }
    // this.sidebarService.setEmptyFolder(data);
    // folder.mode = 'edit';
    // this.sidebarService.setSideBarFoldersFromSideBar(folder);
    // console.log("here is folder",folder);
  }

  /*---------------------
    | Create Empty Folder
    ----------------------*/
  createEmptyFolder() {
    this.isCreateEmptyFolderSubmitted = true;
    if (!this.createEmptyFolderForm.value.folderName.trim()) {
      this.createEmptyFolderForm.patchValue({ folderName: '' });
      return;
    }
    if (this.createEmptyFolderForm.valid) {
      this.ngxLoader.start();
      if (this.folderbutton == 'Save') {
        this.tripservice
          .addnewFolder({
            folderName: this.createEmptyFolderForm.value.folderName.trim(),
            tripIds: [],
          })
          .subscribe(
            (response) => {
              if (response.code && response.code == 400) {
                this.ngxLoader.stop();
                this.toastr.warning(response.message, 'warning');
                return;
              }
              this.toastr.success(response.message, 'Success');
              this.isCreateEmptyFolderSubmitted = false;
              this.createEmptyFolderForm.reset();
              this.ngxLoader.stop();
              this.modalFolder.hide();
              this.getFolders();
            },
            (error) => {
              this.modalFolder.hide();
              this.ngxLoader.stop();
              this.toastr.error('Server error, try again.', 'Error');
            }
          );
      } else {
        this.tripservice
          .updateFolder({
            folderName: this.createEmptyFolderForm.value.folderName.trim(),
            id: this.folderID,
          })
          .subscribe(
            (response) => {
              if (response.code && response.code == 400) {
                this.ngxLoader.stop();
                this.toastr.warning(response.message, 'warning');
                return;
              }
              this.toastr.success(response.message, 'Success');
              this.isCreateEmptyFolderSubmitted = false;
              this.folderbutton = 'Save';
              this.createEmptyFolderForm.reset();
              this.ngxLoader.stop();
              this.modalFolder.hide();
              this.getFolders();
            },
            (error) => {
              this.modalFolder.hide();
              this.ngxLoader.stop();
              this.toastr.error('Server error, try again.', 'Error');
            }
          );
      }
    }
  }

  /*---------------------
    | Get Group Users
    ----------------------*/
  getGroupUsers(groupID, event) {
    let node = event.srcElement.parentNode;
    if (event.srcElement.parentNode.classList.contains('folder-heading')) {
      node = event.srcElement.parentNode.parentNode;
    }

    if (!node.classList.contains('active')) {
      this.router.navigate(['/database']);
    } else {
      this.router.navigate(['/database/group'], {
        queryParams: { groupId: groupID },
      });
    }

    if (!node.classList.contains('active')) {
      // this.ngxLoader.start();
      // this.userserive.getGroupUsers(groupID).subscribe((response) => {
      //   this.router.navigate(['/group'], {
      //     queryParams: { groupId: groupID },
      //     queryParamsHandling: 'merge', // Preserve existing query params
      // });
      // this.router.navigate(['/group'], {queryParams: {groupId: groupID}});
      //   this.SelectedGroupIDForUsers = groupID;
      //   console.log('this.SelectedGroupIDForUsers: ', this.SelectedGroupIDForUsers);
      //   this.GroupUsers = response.data.users;
      //   this.ngxLoader.stop();
      // });
    }
    this.cdr.detectChanges();
  }

  /* Delete Folder */
  deleteFolder(id) {
    this.ngxLoader.start();
    const data = { folderIds: [id] };
    this.userserive.deleteFolder(data).subscribe((option) => {
      this.sidebarService.notifyFolderDeleted(option);
      // this.toastr.success(option.message, 'Success');
      this.ngxLoader.stop();
      this.getFolders();
    });
  }

  showTripAlert(id, name) {
    console.log('id: ', id);
    Swal.fire({
      title: `Are you sure want to delete ${name} ?`,
      text: '',
      icon: 'warning',
      // confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.deleteFolder(id);
      }
    });
    // this.toastr.info('The Geo-Fence boundary will alert via notification the Trip Admin and Trip Officials, of Trip Participants leaving a designated area at the time of the event.', 'Alert', {
    //     positionClass: 'toast-top-center',
    //     preventDuplicates: true,
    //   });
  }

  getFolders() {
    this.tripservice.getAllFolders().subscribe((res) => {
      this.Folders = res.data;
      this.sidebarService.notifyFolderDeleted(res);
      // this.sidebarService.setSideBarFoldersFromSideBar(res.data);
    });
  }

  /* get admin notifications */
  getAllNotifications() {
    this.ngxLoader.start();
    this.userserive.getNotifications().subscribe((res) => {
      this.ngxLoader.stop();
      // this.AdminNotifications = res.data;
      this.AdminNotifications = res.data.map((notification) => {
        notification.timestamp = moment(notification.createdDate).unix();
        return notification;
      });

      this.AdminNotifications = this.AdminNotifications.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });

      let lastItenaryDate = '';
      let finalItenaryList = [];
      this.AdminNotifications.map((itinerary, index) => {
        if (lastItenaryDate == moment(itinerary.createdDate).format('D')) {
          finalItenaryList[finalItenaryList.length - 1].push(itinerary);
        } else {
          finalItenaryList[finalItenaryList.length] = [itinerary];
          lastItenaryDate = moment(itinerary.createdDate).format('D');
        }
      });
      this.AdminNotifications = finalItenaryList;

      if (this.AdminNotifications.length > 0) {
        var todayDate = moment().format('L');
        var dateOne = moment(this.AdminNotifications[0][0].createdDate).format(
          'L'
        );
        if (todayDate == dateOne) {
          this.AdminNotifications[0][0].istoday = true;
        }
        if (this.AdminNotifications.length > 1) {
          var todayDate = moment().subtract(1, 'days').format('L');
          var dateOne = moment(
            this.AdminNotifications[1][0].createdDate
          ).format('L');
          if (todayDate == dateOne) {
            this.AdminNotifications[1][0].isyesterday = true;
          }
        }
      }
    });
  }

  // /* get admin notifications */
  // compareForDates(date,istoday){
  //     if(istoday){
  //         var todayDate = new Date(); //Today Date
  //         var dateOne = new Date(date);
  //         if(todayDate == dateOne){
  //             return 'Today'
  //         }else{

  //         }
  //     }else{
  //         let dte = new Date();
  //         dte.setDate(dte.getDate() - 1);
  //         var dateOne = new Date(date);
  //         if(dte == dateOne){
  //             return 'Today'
  //         }else{

  //         }
  //     }
  // }

  setSidebarOption() {
    this.sidebarService.getSidebarOption().subscribe((option) => {
      this.changeSidebartwo(option);
    });
    this.cdr.detectChanges();
  }

  // getTripName(id){
  //     const TripDetails =  this.tripListing.find(x => x.chTrip.id == id);
  //     console.log("here are filter trip",TripDetails);
  // }

  checkTarget(event) {
    if (event.target.id == 'Ellipse_41' || event.target.id == 'Ellipse_27') {
      return;
    }

    let node = event.srcElement.parentNode;
    if (event.srcElement.parentNode.classList.contains('folder-heading')) {
      node = event.srcElement.parentNode.parentNode;
    }

    if (
      (node.classList.contains('toggle') ||
        node.classList.contains('sub-menu-wrap')) &&
      node.classList.contains('active')
    ) {
      node.classList.remove('active');
      return;
    }

    let result = [];
    node = event.srcElement.parentNode.parentNode.firstChild;
    if (event.srcElement.parentNode.classList.contains('folder-heading')) {
      node = event.srcElement.parentNode.parentNode.parentNode.firstChild;
    }

    while (node.nextElementSibling || node.nextSibling) {
      if (
        (node?.classList?.contains('toggle') ||
          node?.classList?.contains('sub-menu-wrap')) &&
        node.classList.contains('active')
      ) {
        node.classList.remove('active');
      }
      node = node.nextElementSibling || node.nextSibling;
    }

    if (
      event.srcElement.parentNode.classList.contains('toggle') ||
      event.srcElement.parentNode.classList.contains('sub-menu-wrap')
    ) {
      event.srcElement.parentNode.classList.add('active');
    } else if (
      event.srcElement.parentNode.parentNode.classList.contains('sub-menu-wrap')
    ) {
      event.srcElement.parentNode.parentNode.classList.add('active');
    }
    this.cdr.detectChanges();
  }

  // changeSidebartwo(data) {
  //   if (data == 'subAdminListing'){
  //     this.subAdminActive = true;

  //     this.databaseActive = false;
  //     this.manageActive = false;
  //     this.archiveActive = false;
  //     this.addNewActive = false;
  //     this.addTripActive = false;
  //     this.shouldShow = false;

  //   }
  //   if (data == 'manage') {
  //     this.manageActive = true;
  //     this.archiveActive = false;
  //     this.addNewActive = false;
  //     this.databaseActive = false;
  //     this.subAdminActive = false;

  //     this.shouldShow = true;
  //   }
  //   if (data == 'archive') {
  //     this.manageActive = false;
  //     this.archiveActive = true;
  //     this.addNewActive = false;
  //     this.databaseActive = false;
  //     this.subAdminActive = false;

  //     this.shouldShow = true;
  //   }
  //   if (data == 'addnew') {
  //     this.manageActive = false;
  //     this.archiveActive = false;
  //     this.addNewActive = true;
  //     this.databaseActive = false;
  //     this.subAdminActive = false;

  //     this.shouldShow = true;
  //   }
  //   if (data == 'database') {
  //     this.databaseActive = true;
  //     this.manageActive = false;
  //     this.archiveActive = false;
  //     this.addNewActive = false;
  //     this.subAdminActive = false;

  //     this.shouldShow = true;
  //   }
  //   if (data == 'addtrip') {
  //     this.databaseActive = false;
  //     this.manageActive = false;
  //     this.archiveActive = false;
  //     this.addNewActive = false;
  //     this.addTripActive = true;
  //     this.subAdminActive = false;

  //     this.shouldShow = false;
  //   }

  //   this.sidebarService.changeStatus(this.shouldShow);
  // }

  changeSidebartwo(data) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if(params.archive == "1") {
        data = 'archive'
      }
    });

    this.subAdminActive = false;
    this.databaseActive = false;
    this.manageActive = false;
    this.archiveActive = false;
    this.addNewActive = false;
    this.addTripActive = false;
    this.shouldShow = true;

    if (data == 'subAdminListing') {
      this.subAdminActive = true;
    } else if (data == 'manage') {
      this.manageActive = true;
      this.shouldShow = true;
    } else if (data == 'archive') {
      this.archiveActive = true;
      this.shouldShow = true;
    } else if (data == 'addnew') {
      this.manageActive = true;
      this.shouldShow = true;
    } else if (data == 'database') {
      this.databaseActive = true;
      this.shouldShow = true;
    } else if (data == 'addtrip') {
      this.addTripActive = true;
    }

    if (this.user.chUserDTO.userType === 'SUPER_ADMIN') {
      this.sidebarService.changeStatus(this.shouldShow);
    }
  }

  logout() {
    this.userserive.deleteWebToken().subscribe((res) => {
      console.log('token delete');
    });
    this.modalSettings.hide();
    sessionStorage.getItem('userData')
      ? sessionStorage.removeItem('userData')
      : localStorage.removeItem('cuserData');
    localStorage.removeItem('trip_');
    this.router.navigate(['login']);
  }

  sidebarToggle() {
    this.shouldShow = !this.shouldShow;
    this.sidebarService.changeStatus(this.shouldShow);
  }

  getAbout() {
    this.appInfoService.getCms('about').subscribe(
      (response) => {
        this.aboutDetail = response.data.cmsDescription;
      },
      (error) => {
        this.toastr.error('Unable to get About Infomation ', 'Error');
      }
    );
  }
  getPrivacy() {
    this.appInfoService.getCms('privacyPolicy').subscribe(
      (response) => {
        this.privacyDetail = response.data.cmsDescription;
      },
      (error) => {
        this.toastr.error('Unable to get privacy policy Infomation ', 'Error');
      }
    );
  }
  getTerms() {
    this.appInfoService.getCms('terms').subscribe(
      (response) => {
        this.termsDetail = response.data.cmsDescription;
      },
      (error) => {
        this.toastr.error(
          'Unable to get Terms and Condition Infomation ',
          'Error'
        );
      }
    );
  }

  // openFromProfile(account: TemplateRef<any>) {
  //   this.userserive.getCurrentUserInfo().subscribe((response) => {
  //     this.data = { ...this.adminDetail, ...response.data };

  //     this.adminDetail = { ...this.data };
  //     this.selectedImage = this.adminDetail.imageUrl;
  //   });

  //   this.isHidden = false;
  //   this.modalAccount = this.modalService.show(
  //     account,
  //     Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
  //   );
  //   this.modalRefService.setParentModalRef(this.modalAccount);
  // }

  imgErrorHandler(event) {
    event.target.src = 'assets/images/profile-placeholder-image.svg';
  }
  edit() {
    this.isEditing = true;
  }

  // not taken in use for now
  validateForm() {
    Object.keys(this.changePasswordForm.controls).forEach((key) => {
      const control = this.changePasswordForm.get(key);
      if (control.dirty && control.invalid) {
        this.toastr.error(
          'Password cannot have leading, trailing, or consecutive spaces.',
          'Validation Error'
        );
        // this.showValidationMessage(key);
      }
    });
  }

  // not taken in use for now
  showValidationMessage(key: string) {
    switch (key) {
      case 'currentPassword':
        this.toastr.error(
          'Please provide the current password.',
          'Validation Error',
          { closeButton: true }
        );
        break;
      case 'newPassword':
        const hasLeadingTrailingSpaces = /^\s|\s$/.test(
          this.changePasswordForm.value.newPassword
        );
        if (hasLeadingTrailingSpaces) {
          this.toastr.error(
            'No spaces are allowed in the new password.',
            'Validation Error',
            { closeButton: true }
          );
        }
        // else {
        //   this.toastr.error('Please provide a new password without spaces.', 'Validation Error', { closeButton: true });
        // }
        break;
      case 'confirmPassword':
        this.toastr.error(
          'Please provide the confirm password.',
          'Validation Error',
          { closeButton: true }
        );
        break;
    }
  }

  noWhitespaceValidator(control) {
    if (control.value) {
      const hasLeadingTrailingSpaces = /^\s|\s$/.test(control.value);
      const hasSpacesWithin = /\s/.test(control.value);
      const isValid = !hasLeadingTrailingSpaces && !hasSpacesWithin;
      return isValid ? null : { leadingTrailingSpaces: true };
    }
    return null;
  }

  changePassword() {
    this.resetPasswordSubmited = true;
    if (this.changePasswordForm.valid) {
      if (
        this.changePasswordForm.value.newPassword !=
        this.changePasswordForm.value.confirmPassword
      ) {
        return;
      }

      this.adminService
        .changePassword({
          currentPassword: this.changePasswordForm.value.currentPassword,
          newPassword: this.changePasswordForm.value.newPassword,
        })
        .subscribe(
          (response) => {
            this.resetPasswordSubmited = false;
            if (response.code == 400) {
              this.toastr.error(response.message, 'Error');
            } else {
              this.toastr.success(response.message, 'Success');
              this.changePasswordForm.reset();
              this.modalChangepassword.hide();
              // Optionally, you can also clear the validity of the form controls
              // this.changePasswordForm.controls.currentPassword.setErrors(null);
              // this.changePasswordForm.controls.newPassword.setErrors(null);
              // this.changePasswordForm.controls.confirmPassword.setErrors(null);
            }
          },
          (error) => {
            this.toastr.error('Unable to change password', 'Error');
          }
        );
    }
  }

  updateProfile() {
    this.profileSumitted = true;
    this.isEditing = false;
    if (this.editAdminForm.invalid) {
      return;
    }
    if (this.editAdminForm.valid) {
      // this.editAdminForm.value.phone = "+"+this.selectedCountryCode+"-"+this.editAdminForm.value.phone;
      // this.editAdminForm.value.phone = "+91-8352805272"

      var clean = ('' + this.editAdminForm.value.phone).replace(/\D/g, '');
      var phoneResult = this.phoneNumberService.formatPhoneNumber(
        clean,
        this.editAdminForm.value.countryCode
      );
      console.log('cleaned: phoneResult ', phoneResult);

      var formData = new FormData();
      if (this.fileToUpload) {
        // formData.append('image', this.profileImageFile);
        formData.append('image', this.fileToUpload, this.filesSelectName);
      }
      formData.append(
        'data',
        new Blob(
          [
            JSON.stringify({
              ...this.editAdminForm.value,
              phone: phoneResult,
              id: this.adminDetail.id,
              maxUser: null,
            }),
          ],
          { type: 'application/json' }
        )
      );
      this.adminService.updateAdmin(formData).subscribe(
        (response) => {
          if (response.code && response.code == 400) {
            this.toastr.error(response.message, 'Error');
            this.ngxLoader.stop();
            return;
          }
          this.fileToUpload = null;
          this.filesSelectName = null;

          response.data.phone = this.phoneNumberService.displayNumbers(
            response.data.phone
          ).phoneNumber;
          this.editAdminForm.patchValue(response.data);
          this.adminDetail = { ...this.adminDetail, ...response.data };
          let userData = storedUserData();
          userData.chUserDTO = this.adminDetail;
          sessionStorage.getItem('userData')
            ? sessionStorage.setItem('userData', JSON.stringify(userData))
            : localStorage.setItem('cuserData', JSON.stringify(userData));
          if (!this.adminDetail.imageUrl) {
            this.adminDetail.imageUrl =
              'assets/images/profile-placeholder-image.svg';
          }

          this.profileSumitted = false;
          this.userserive.getCurrentUserInfo().subscribe((response) => {
            this.data = { ...this.adminDetail, ...response.data };
            this.adminDetail = { ...this.data };
            // this.selectedImage = this.adminDetail.imageUrl
          });
          this.toastr.success(response.message, 'Success');
        },
        (error) => {
          console.log('error: ', error);
          if (error.error.statusCodes == 403) {
            // this.toastr.error(error.error.message, 'Error');
            this.modalAccount.hide();
            sessionStorage.getItem('userData')
              ? sessionStorage.removeItem('userData')
              : localStorage.removeItem('cuserData');
            this.router.navigate(['/login']);
          } else {
            this.toastr.error('Unable to update profile', 'Error');
          }
        }
      );
    }
  }

  // onSelectImage(event) {
  //     if (event.target.files && event.target.files[0] && event.target.files[0].type.split('/')[0] == 'image') {
  //         var reader = new FileReader();

  //         this.profileImageFile = event.target.files[0];

  //         reader.readAsDataURL(event.target.files[0]);

  //         reader.onload = (event) => {
  //             this.selectedImage = event.target.result;
  //         }
  //     }
  // }

  onFileSelect(event: any) {
    // const selectedFile = event.target.files[0];
    // this.imageCompress.uploadFile().then(({ image, orientation }) => {
    //   // this.imageCompress
    //   //   .compressFile(image, orientation, 50, 50)
    //   //   .then((result) => {
    //   //     var formData = new FormData();
    //       const file = this.DataURIToBlob(image);
    //       this.createImageFromBlob(file);

    //       this.profileImageFile = file;
    //     // });
    // });

    if (event.target.files && event.target.files.length > 0) {
      this.filesSelectName = event.target.files[0].name;
      this.selectedFile = <File>event.target.files;
      var reader = new FileReader();
      this.selectedFileType = event.target.files[0]?.type;
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.url = this.b64toBlob(reader.result);
      };
    }
  }

  b64toBlob(dataURI) {
    this.selectedImage = dataURI;
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    this.fileToUpload = new Blob([ab], { type: this.selectedFileType });
    return this.fileToUpload;
  }

  // createImageFromBlob(image: Blob) {
  //   let reader = new FileReader();
  //   reader.addEventListener(
  //     'load',
  //     () => {
  //       this.selectedImage = reader.result;
  //     },
  //     false
  //   );
  //   if (image) {
  //     reader.readAsDataURL(image);
  //   }
  // }

  // DataURIToBlob(dataURI: string) {
  //   const splitDataURI = dataURI.split(',');
  //   const byteString =
  //     splitDataURI[0].indexOf('base64') >= 0
  //       ? atob(splitDataURI[1])
  //       : decodeURI(splitDataURI[1]);
  //   const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  //   const ia = new Uint8Array(byteString.length);
  //   for (let i = 0; i < byteString.length; i++)
  //     ia[i] = byteString.charCodeAt(i);

  //   return new Blob([ia], { type: mimeString });
  // }

  //sprint 6
  updateTripStatus() {
    const data = {
      tripID: this.queryParams.trip,
      chTripStatus: 'OPEN',
    };
    this.tripservice.updateTripStatus(data).subscribe(
      (response) => {
        if (response.code && response.code == 400) {
          this.ngxLoader.stop();
          this.toastr.error(response.message, 'Error');
          return;
        }
      },
      (error) => {
        this.ngxLoader.stop();
        this.toastr.error('Unable to update trip. try again', 'Error');
      }
    );
  }
  // sprint 7
  // goToHelpLink(url: string){
  //     window.open(url, "_self");
  // }
  // sprint 7

  //   password() {
  //     this.show = !this.show;
  // }

  currentPassword() {
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  newPassword() {
    this.showNewPassword = !this.showNewPassword;
  }

  confirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
