import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ModelReferenceService } from '../model-reference.service';
import Swal from 'sweetalert2';
import { storedUserData } from './const-helper';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

    private errorSubject = new BehaviorSubject<boolean>(false);
    private errorToasterShown = false;
    private authorizationFailed = false;

    constructor(
        public router: Router,
        private toastr: ToastrService,
        private ngxLoader: NgxUiLoaderService,
        private modalService: ModelReferenceService,
    ) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let userData = storedUserData() || '{}';
        if (userData.token_id) {
            req = req.clone({
                headers: new HttpHeaders({
                    'Authorization': 'Bearer ' + userData.token_id
                })
            });
        } else {
            req = req.clone();
        }

        // If authorization has failed, prevent further API calls
        if (this.authorizationFailed) {
            return throwError('Authorization failed');
        }

        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                // Handle success response globally
                if (event instanceof HttpResponse && event.body.code == 403) {
                    this.handleAuthorizationFailed(event.body.message);
                }
            }),
            catchError((error: HttpErrorResponse) => {
                // Handle error responses
                if (error.status === 401 || error.status === 403 || (error.error && error.error.statusCodes == 403)) {

                    if (error.status === 401 && error.error.message == 'error.http.401') {
                        error.error.message = (error.error.message == 'error.http.401') ? 'Invalid Password' : error.error.message;
                        this.ngxLoader.stop();
                    }

                    this.handleAuthorizationFailed(error.error.message);
                } else if (error.status >= 500) {
                    // Handle other server errors here, if needed
                    // For now, we're not displaying Toastr for other server errors
                    return throwError(error);
                }
                return throwError(error);
            }),
            switchMap((result) => {
                // Check authorization status before continuing with the observable chain
                return this.authorizationFailed ? throwError('Authorization failed') : of(result);
            })
        );
    }

    private handleAuthorizationFailed(message: string): void {
        // if (!this.errorSubject.value) {
            // this.toastr.error(message, 'Error');
            // this.errorSubject.next(true);
            this.authorizationFailed = true; // Set flag to true to prevent further API calls
            if (localStorage.getItem('cuserData')) {
              const userType = JSON.parse(localStorage.getItem('cuserData')).chUserDTO.userType;
              if (userType !== 'SUPER_ADMIN') {
                sessionStorage.clear();
                localStorage.clear();
              } else {
                sessionStorage.clear();
                window.location.reload();
              }
            }
            Swal.close();
            this.modalService.clearModalRef();
            this.router.navigate(['/login'],{state:{status : 401,message : message}}).then(() => {
                this.authorizationFailed = false; // Reset the flag after navigating to login
            });
        // }


    }
}
