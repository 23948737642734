import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToWords'
})
export class numberToWordsPipe implements PipeTransform {

  special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
  deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

  transform(value: any, args?: any): any {
    if (value != undefined && value != '' && value != null) {
      if(value < 100){
        if (value < 20) return this.special[value].charAt(0).toUpperCase() + this.special[value].slice(1);
        if (value%10 === 0) return this.deca[Math.floor(value/10)-2].charAt(0).toUpperCase() + this.deca[Math.floor(value/10)-2].slice(1) + 'ieth';
        let position = this.deca[Math.floor(value/10)-2] + 'y-' + this.special[value%10];
        if(position == "twelvth"){
          position = "twelfth";
        } else if(position == "fourtieth") {
          position = "fortieth";
        }
        return position.charAt(0).toUpperCase() + position.slice(1);
      } else {
        return "#" + value.toString();
      }
    }
    else {
      return ''
    }
  }

}
