import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AppInfoService {

    constructor(
        public http: HttpClient,
        public router: Router,
    ) { }

    public getCms(key) {
        return this.http.get<any>(environment.base_url + "ch-cms/cmsKey/"+key)
    }
    
    public updateCms(data) {
        return this.http.put<any>(environment.base_url + "ch-cms/update",data)
    }
}
