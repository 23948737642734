<header>
    <div class="header-wrapper" >
        <div>
            <a href="javascript:void(0)" [inlineSVG]="'assets/images/Apperone_Pro.svg'" class="sidebar-logo"></a>
        </div>
        <div class="sidebar-profile">
          <div class="remaning-users-label" *ngIf="checkRemainingCondition">
            <span>{{ RemainingUsers }} Users Remaining</span>
          </div>
          <ng-container *ngIf="checkCondition">
              <app-search-bar></app-search-bar>
          </ng-container>
            <div class="notification-box" *ngIf="adminDetail.userType !== 'SUPER_ADMIN'" >
                <div [inlineSVG]="'assets/images/header-notification.svg'" (click)="notificationsModal(notifications)"></div>
            </div>
            <div style="position: relative; padding-right: 20px; " >
                <figure class="figure-small" >
                    <img [src]="adminDetail.imageUrl" alt="profile-image" (error)="imgErrorHandler($event)" (click)="openDropdown(dropdown)">
                </figure>
              </div>
        </div>
    </div>
</header>

<ng-template #dropdown>
  <div class="sidebar-profile">
    <div class="dropdown headre-dropdown">
      <div class="profile-info-inner">
        <figure class="profile-image">
          <img
            [src]="adminDetail.imageUrl"
            alt="profile-image"
            (error)="imgErrorHandler($event)"
          >
        </figure>
        <div class="info-box">
          <h4>{{ adminDetail.firstName }} {{ adminDetail.lastName }}</h4>
          <span>{{ adminDetail.email }}</span>
          <a href="javascript:void(0)" (click)="openFromProfile(account)">view profile</a>
        </div>
      </div>
      <div class="logout-btn" *ngIf="checkSession()">
        <a href="javascript:void(0)" (click)="logout()">
          <i [inlineSVG]="'assets/images/logout.svg'"></i>
        <div>Log Out</div>
        </a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #account>
  <div class="settings-main">
      <button type="button" class="close" aria-label="Close" (click)="modalAccount.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
      <form [formGroup]="editAdminForm" (ngSubmit)="updateProfile()">
          <div class="modal-header">
              <div class="header-info">
                  <a href="javascript:void(0)" *ngIf="isHidden" (click)="modalAccount.hide()"><i
                          [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
                  <a href="javascript:void(0)" *ngIf="!isHidden" (click)="modalAccount.hide()"><i
                          [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Go Back</a>
                  <h3>My Account</h3>
                  <input *ngIf="isEditing" type="submit" value="Update" class="btn-edit-save">
                  <input *ngIf="!isEditing" type="button" (click)="edit()" value="Edit" class="btn-edit-save">
                  <!-- <a href="javascript:void(0)" class="btn-edit-save">EDIT</a> -->
              </div>
          </div>
          <ng-scrollbar>
              <div class="modal-body">
                  <div class="my-account-form">
                      <div class="auth-wrap">
                          <div class="auth-form-fields">
                              <div class="upload-profile">
                                  <figure>
                                      <img class="profile-placeholder-img" [src]="selectedImage"
                                          alt="profile-placeholder-img" (error)="imgErrorHandler($event)" />
                                  </figure>
                                  <!-- <input type="file" id="upload-profile-pic" (change)="onFileSelect($event)" #f_input >
                                  <label *ngIf="isEditing" (click)="f_input.click()"
                                      [inlineSVG]="'assets/images/icon-plus.svg'"></label> -->

                                  <input type="file" id="upload-profile-pic" (change)="onFileSelect($event)" accept="image/png, image/jpeg, image/jpg, image/gif">
                                  <label *ngIf="isEditing" for="upload-profile-pic" [inlineSVG]="'assets/images/icon-plus.svg'"></label>
                              </div>
                              <ul>
                                  <li>
                                      <div class="form-grp">
                                          <input type="text" class="input-grp" [readonly]="!isEditing"
                                              formControlName="firstName" placeholder="First Name"
                                              [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.firstName.errors }">
                                          <div class="invalid-feedback"
                                              *ngIf="profileSumitted && editAdminForm.controls.firstName.errors">
                                              <div *ngIf="editAdminForm.hasError('required','firstName')">
                                                  Please provide first name.
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="form-grp">
                                          <input type="text" class="input-grp" [readonly]="!isEditing"
                                              formControlName="lastName" placeholder="Last Name"
                                              [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.lastName.errors }">
                                          <div class="invalid-feedback"
                                              *ngIf="profileSumitted && editAdminForm.controls.lastName.errors">
                                              <div *ngIf="editAdminForm.hasError('required','lastName')">
                                                  Please provide last name.
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="form-grp">
                                          <input type="email" class="input-grp" formControlName="email"
                                              placeholder="Email" disabled
                                              [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.email.errors }">
                                          <div class="invalid-feedback"
                                              *ngIf="profileSumitted && editAdminForm.controls.email.errors">
                                              <div *ngIf="editAdminForm.hasError('required','email')">
                                                  Please provide email.
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="form-grp">
                                          <input type="text" class="input-grp" formControlName="phone"
                                              placeholder="Phone Number" disabled
                                              [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.phone.errors }">
                                          <div class="invalid-feedback"
                                              *ngIf="profileSumitted && editAdminForm.controls.phone.errors">
                                              <div *ngIf="editAdminForm.hasError('required','phone')">
                                                  Please provide phone number.
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="form-grp">
                                          <input type="text" class="input-grp" [readonly]="!isEditing"
                                              formControlName="position" placeholder="Position"
                                              [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.position.errors }">
                                          <div class="invalid-feedback"
                                              *ngIf="profileSumitted && editAdminForm.controls.position.errors">
                                              <div *ngIf="editAdminForm.hasError('required','position')">
                                                  Please provide position.
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="form-grp">
                                          <input type="text" class="input-grp" [readonly]="!isEditing"
                                              formControlName="organization" placeholder="Organization"
                                              [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.organization.errors }">
                                          <div class="invalid-feedback"
                                              *ngIf="profileSumitted && editAdminForm.controls.organization.errors">
                                              <div *ngIf="editAdminForm.hasError('required','organization')">
                                                  Please provide organization.
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </ng-scrollbar>
          <div class="modal-footer">

          </div>
      </form>
  </div>
</ng-template>

<!-- Notifications Popup -->
<ng-template #notifications>
  <div class="settings-main">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalNotifications.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <form>
      <div class="modal-header">
        <h4>Notifications</h4>
        <!-- <a href="javascript:void(0)" class="btn-clear-all">Clear All</a> -->
      </div>
      <ng-scrollbar>
        <div class="modal-body">
          <div
            class="notifications-main"
            *ngIf="AdminNotifications.length > 0"
          >
            <div *ngFor="let notification of AdminNotifications">
              <div class="notifications-wrap">
                <h4 *ngIf="notification[0].istoday">Today</h4>
                <h4 *ngIf="notification[0].isyesterday">Yesterday</h4>
                <h4 *ngIf="!notification[0].isyesterday && !notification[0].istoday">
                  {{notification[0].createdDate | date }}</h4>
                <ul class="notifications-listing">
                  <li *ngFor="let not of notification">
                    <div class="notifications-listing-wrap">
                      <strong>{{not.notificationTitle}}</strong>
                      <p>{{not.notificationDescription}}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="notifications-main"
            *ngIf="AdminNotifications.length == 0"
          >
            No Notifications
          </div>
        </div>
      </ng-scrollbar>
      <div class="modal-footer">
        <a
          href="javascript:void(0)"
          class="btn-blue min-btn"
          (click)="modalNotifications.hide()"
        >Done</a>
      </div>
    </form>
  </div>
</ng-template>
