import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class MiscService {

    constructor(
        public http: HttpClient,
    ) { }
        
    public getCountryCode() {
        return this.http.get<any>(environment.base_url + "ch-countries/distinct/code")
    }

    public validReset(key) {
        return this.http.get<any>(environment.base_url + "ch-user/reset-key/"+key)
    }
    
    
}
