import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthServices {

    constructor(
        public http: HttpClient,
        public router: Router,
    ) { }

    public login(loginForm) {
        return this.http.post<any>(environment.base_url + "ch-user/authenticate", {...loginForm,loginType:'SUPER_ADMIN',"deviceId":null,"deviceToken":null,"deviceType":"WEB", "forced": false, rememberMe: true})
        .pipe(map(res => {
            if (res.status === 200 && res.data) {
                if(res.data.chUserDTO.userType == "SUPER_ADMIN" ||
                res.data.chUserDTO.userType == "ADMIN" ||
                res.data.chUserDTO.userType == "INCHARGE_ADMIN"){
                    localStorage.setItem("cuserData", JSON.stringify(res.data))
                    localStorage.setItem('calenderView', 'false');
                }
            }
                return res;
            }));
    }

    public forgotPassword(email){
        return this.http.post<any>(environment.base_url + `ch-user/reset-password/init?mail=${email}`,null);
    }

    public resetPassword(data){
        return this.http.post<any>(environment.base_url + `ch-user/reset-password/finish`,data);
    }

    public updateWebToken(data){
        return this.http.post<any>(environment.base_url + `ch-user/store/web-token?token=${data.token}&userType=${data.userType}`,null);
    }
}
