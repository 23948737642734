// import { Directive, ElementRef, HostListener, Input } from '@angular/core';
// import { NgControl } from '@angular/forms';

// @Directive({
//   selector: 'input[numbersOnly]'
// })
// export class NumberDirective {

//   constructor(private _el: ElementRef) { }

//   @HostListener('input', ['$event']) onInputChange(event:any) {
//     const initalValue = this._el.nativeElement.value;
//     this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
//     if ( initalValue !== this._el.nativeElement.value) {
//       event.stopPropagation();
//     }
//   }

// }

import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[numbersOnly]'
})
export class NumberDirective {
  @Input() maxLength: number = 5; // Default max length is 4

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initalValue = this._el.nativeElement.value;

    // Replace any non-numeric characters
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');

    // Enforce the max length restriction
    if (this._el.nativeElement.value.length > this.maxLength) {
      this._el.nativeElement.value = this._el.nativeElement.value.slice(0, this.maxLength);
    }

    // Stop propagation if there's a change
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
