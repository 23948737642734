import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { storedUserData } from 'src/app/shared/helpers/const-helper';
import { SidebarService } from 'src/app/shared/sidebar/sidebar.service';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent implements OnInit {
  sidebarToggle: boolean;
  user = storedUserData();
  constructor(private sidebarService: SidebarService , public router: Router) {}

  ngOnInit() {
    this.sidebarService.getSidebar().subscribe((status) => {
      this.sidebarToggle = status;
    });
  }
}
