import { Directive, HostListener, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNumberDecimal]'
})
export class NumberDecimalDirective {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // Allow special keys and shortcuts
    if (this.specialKeys.indexOf(event.key) !== -1 || 
        (event.key === 'a' && event.ctrlKey === true) || // Ctrl+A
        (event.key === 'c' && event.ctrlKey === true) || // Ctrl+C
        (event.key === 'v' && event.ctrlKey === true) || // Ctrl+V
        (event.key === 'x' && event.ctrlKey === true)) { // Ctrl+X
      return;
    }

    const inputElement = this.el.nativeElement as HTMLInputElement;
    const current: string = inputElement.value;
    const next: string = current.concat(event.key);

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue: string = inputElement.value;

    if (inputValue && !String(inputValue).match(this.regex)) {
      inputElement.value = inputValue.slice(0, -1);
    }
  }
}
