import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RegisterInfoService {

  registerData:any = {};

  constructor() { }

  set storeRegisterInfo(data:any) {
      if(data.replace) {
          this.registerData = {};
      } else {
          this.registerData = {...this.registerData,...data};
      }
  }

  get fetchRegeisterInfo() {
      return this.registerData;
  }

}