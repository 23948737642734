import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { EncryptionService } from 'src/app/shared/helpers/encryption.service';

@Component({
  selector: 'app-impersonate',
  template: '<ngx-ui-loader></ngx-ui-loader>',
})
export class ImpersonateComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    private encryptionService: EncryptionService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(value => {
      const email = this.encryptionService.decrypt(value.email);
      if (email) {
        this.adminService.impersonateAuth(email).subscribe(response => {
          sessionStorage.setItem('userData', JSON.stringify(response.data));
          if (response.data.chUserDTO.userType == "ADMIN") {
            this.router.navigate(['/trips'])
          } else if (response.data.chUserDTO.userType == "INCHARGE_ADMIN") {
            this.router.navigate(['/trips'])
          }
        })
      } else {
        this.router.navigate(['/login'])
      }
    });
  }

}
